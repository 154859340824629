//Search
export const ISO_REQUEST_SEARCH_START_LOADING = "ISO_REQUEST_SEARCH_START_LOADING";
export const ISO_REQUEST_SEARCH_END_LOADING = "ISO_REQUEST_SEARCH_END_LOADING";
export const ISO_REQUEST_SEARCH_LOADED = "ISO_REQUEST_SEARCH_LOADED";
export const SET_ISO_REQUEST_FILTER = "SET_ISO_REQUEST_FILTER";

//Notify Search
export const ISO_REQUEST_NOTIFY_START_LOADING = "ISO_REQUEST_NOTIFY_START_LOADING";
export const ISO_REQUEST_NOTIFY_END_LOADING = "ISO_REQUEST_NOTIFY_END_LOADING";
export const ISO_REQUEST_NOTIFY_LOADED = "ISO_REQUEST_NOTIFY_LOADED";
export const SET_ISO_REQUEST_NOTIFY_FILTER = "SET_ISO_REQUEST_NOTIFY_FILTER";

//Edit
export const ISO_REQUEST_START_LOADING = "ISO_REQUEST_START_LOADING";
export const ISO_REQUEST_END_LOADING = "ISO_REQUEST_END_LOADING";
export const ISO_REQUEST_LOADED = "ISO_REQUEST_LOADED";

//Related
export const RELATED_ISO_REQUEST_START_LOADING = "RELATED_ISO_REQUEST_START_LOADING";
export const RELATED_ISO_REQUEST_END_LOADING = "RELATED_ISO_REQUEST_END_LOADING";
export const RELATED_ISO_REQUEST_LOADED = "RELATED_ISO_REQUEST_LOADED";

//Certificate
export const ISO_REQUEST_CERTIFICATE_START_LOADING = "ISO_REQUEST_CERTIFICATE_START_LOADING";
export const ISO_REQUEST_CERTIFICATE_END_LOADING = "ISO_REQUEST_CERTIFICATE_END_LOADING";
export const ISO_REQUEST_CERTIFICATE_LOADED = "ISO_REQUEST_CERTIFICATE_LOADED";
