import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import { api } from "../utils/index";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

import { useSelector } from "react-redux";

const FormEvaluationSASO = (props) => {
    const caiList = useSelector((state) => state.login.data.caiList);
    const [evaluationFormCollapsed, setEvaluationFormCollapsed] = React.useState(true);
    
    return(
        <Card className="card-warning mb-3">
            <CardHeader className="border-0 d-flex align-items-center">
                <CardLoader loading={props.loading} />
                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setEvaluationFormCollapsed(!evaluationFormCollapsed)}>
                    {evaluationFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                    {!evaluationFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                    Evaluation Form ({evaluationFormCollapsed ? "Expand" : "Collapse"})
                </h5>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <Collapse isOpen={!evaluationFormCollapsed} className="collapse">
                    <Row className="gy-2" style={{backgroundColor:"white"}}>
                        <Col xxl={12} md={12}>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <tbody>
                                    <tr>
                                        <td>Applicable CAI Number</td>
                                        <td>
                                            <select className={`form-select form-select-sm w-100 ${props.isValidFieldValue("evaluationForm", "ApplicableCAINumber") ? "" : "is-invalid"}`} disabled={!(props.fboState && props.fboState.canEditForm2)}
                                                value={props.getFieldValueText("evaluationForm", "ApplicableCAINumber")}
                                                onChange={e => props.setFieldValueText("evaluationForm", "ApplicableCAINumber", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {caiList && caiList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.code}>{item.code} - {item.description}</option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>Record Check<sup>1</sup></td>
                                        <td colSpan={3}>Technical Reviewer</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="index-col">2.1</td>
                                        <td>Quality Documents</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityDocuments", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QualityDocuments", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityDocuments", "No")} onChange={e => props.setFieldValue("evaluationForm", "QualityDocuments", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.2</td>
                                        <td>Supplier Declaration of Conformity</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SupplierDeclaration") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SupplierDeclaration", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SupplierDeclaration", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SupplierDeclaration") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SupplierDeclaration", "No")} onChange={e => props.setFieldValue("evaluationForm", "SupplierDeclaration", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.3</td>
                                        <td>Risk Assessment Form</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "RiskAssessmentForm") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "RiskAssessmentForm", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "RiskAssessmentForm", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "RiskAssessmentForm") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "RiskAssessmentForm", "No")} onChange={e => props.setFieldValue("evaluationForm", "RiskAssessmentForm", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.4</td>
                                        <td>At least 2 (two) visible and clear PHOTOS (one photo for labeling, one photo for product)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "Photos", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Photos", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "Photos", "No")} onChange={e => props.setFieldValue("evaluationForm", "Photos", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.5</td>
                                        <td>ISO Certification (ISO 9001, ISO 16949, etc. related with TR)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ISOCertification", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ISOCertification", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ISOCertification", "No")} onChange={e => props.setFieldValue("evaluationForm", "ISOCertification", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ISOCertification", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "ISOCertification", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.6</td>
                                        <td>Factory Aduit Report</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "FactoryAuditReport", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "FactoryAuditReport", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "FactoryAuditReport", "No")} onChange={e => props.setFieldValue("evaluationForm", "FactoryAuditReport", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "FactoryAuditReport", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "FactoryAuditReport", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.7</td>
                                        <td>Technical Documentation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TechnicalDocumentation", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TechnicalDocumentation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TechnicalDocumentation", "No")} onChange={e => props.setFieldValue("evaluationForm", "TechnicalDocumentation", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TechnicalDocumentation", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TechnicalDocumentation", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr>
                                        <td colSpan={8} style={{textAlign:"center", border: "none"}}><h5><b>Evaluation Plan & Evaluation<sup>2</sup></b></h5></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"14%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"14%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Project Number from COC System:</b>
                                        </td>
                                        <td colSpan={2}>
                                            {(props.fboState && props.fboState.ticket && props.fboState.ticket.requestNumber) || ""}&nbsp;
                                        </td>
                                        <td colSpan={1}>
                                            <b>Certificate Number</b>
                                        </td>
                                        <td colSpan={3}>
                                            <div>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateNumberType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateNumberType", "New")} onChange={e => props.setFieldValue("evaluationForm", "CertificateNumberType", "New", e.target.checked)} /> New
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2 pt-1">
                                                        <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateNumberType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateNumberType", "Existing")} onChange={e => props.setFieldValue("evaluationForm", "CertificateNumberType", "Existing", e.target.checked)} /> Existing Certificate No<sup>3</sup>:
                                                    </div>
                                                    <div className="flex-grow-1 w-100">
                                                        <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                            value={props.getFieldValueText("evaluationForm", "ExistingCertificateNumber")}
                                                            onChange={e => props.setFieldValueText("evaluationForm", "ExistingCertificateNumber", e.target.value)}
                                                        />
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td colSpan={8}>Product information</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Product Name and Model Type</td>
                                        <td colSpan={6}>Product name and/or Model Type has been detailed in the SABER platform. Please verify.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Product HS CODE</td>
                                        <td colSpan={6}>Please refer to request, based on SABER platform</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Compliance standard(s) proposal</td>
                                        <td colSpan={6}>
                                            <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                value={props.getFieldValueText("evaluationForm", "ComplianceStandardProposal")}
                                                onChange={e => props.setFieldValueText("evaluationForm", "ComplianceStandardProposal", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td colSpan={1} style={{textAlign: "center"}}>#</td>
                                        <td colSpan={7}>Points to be checked<sup>4</sup></td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1</td>
                                        <td colSpan={5}>Application Review have a positive verdict?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ApplicationPositiveVerdict") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ApplicationPositiveVerdict", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ApplicationPositiveVerdict", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ApplicationPositiveVerdict") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ApplicationPositiveVerdict", "No")} onChange={e => props.setFieldValue("evaluationForm", "ApplicationPositiveVerdict", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2</td>
                                        <td colSpan={5}>Certification evaluation plan available and filled in correctly</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationEvaluationPlan") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationEvaluationPlan", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "CertificationEvaluationPlan", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationEvaluationPlan") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationEvaluationPlan", "No")} onChange={e => props.setFieldValue("evaluationForm", "CertificationEvaluationPlan", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">4</td>
                                        <td colSpan={5}>Are all docs readable when scanned in?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "DocsReadable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "DocsReadable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "DocsReadable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "DocsReadable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "DocsReadable", "No")} onChange={e => props.setFieldValue("evaluationForm", "DocsReadable", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">5</td>
                                        <td colSpan={5}>Check representative samples photos of the proposed production</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SamplesPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SamplesPhotos", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SamplesPhotos", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SamplesPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SamplesPhotos", "No")} onChange={e => props.setFieldValue("evaluationForm", "SamplesPhotos", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>Review of Conflict of Interest</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>I undertake to inform the Technical Manager or Group Team Leader in the event of any of the following matters related to the specified client.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("evaluationForm", "HaveRelationship", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "HaveRelationship", "Yes", e.target.checked)} />
                                        </td>
                                        <td>In the management of the client, I have close family members and person/people with whom I have a personal or business relationship that can affect objectivity.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("evaluationForm", "PerformedAudit", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PerformedAudit", "Yes", e.target.checked)} />
                                        </td>
                                        <td>In the last two years, I have performed internal audit and training services in the client, including quality consultancy.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("evaluationForm", "ProvidedServices", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ProvidedServices", "Yes", e.target.checked)} />
                                        </td>
                                        <td>I provided services in the supply, design, service provider developer of the client's products, operation, and development of product-related processes.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("evaluationForm", "PreventImpartiality", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PreventImpartiality", "Yes", e.target.checked)} />
                                        </td>
                                        <td>There are illegal financial and other conditions that prevent my impartiality.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>2.1 Quality Documents Evaluation</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Please choose related QD</td>
                                        <td>
                                            <div>
                                                <input type="checkbox" checked={props.getFieldValue("evaluationForm", "QdTestReport", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QdTestReport", "Yes", e.target.checked)} /> Test Report
                                            </div>
                                            <div>
                                                <input type="checkbox" checked={props.getFieldValue("evaluationForm", "QdApprovalCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QdApprovalCertificate", "Yes", e.target.checked)} /> Type Approval Certificate (Automotive Spare Parts, Motorcycles, Tires-Semi Tires)
                                            </div>
                                            <div>
                                                <input type="checkbox" checked={props.getFieldValue("evaluationForm", "QdCertificates", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QdCertificates", "Yes", e.target.checked)} /> Type Certificates (SASO, IEC, GSO etc.)
                                            </div>
                                            <div>
                                                <input type="checkbox" checked={props.getFieldValue("evaluationForm", "QdPID", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QdPID", "Yes", e.target.checked)} /> PID (Product Identical Declaration)
                                            </div>
                                            <div>
                                                <input type="checkbox" checked={props.getFieldValue("evaluationForm", "QdCECertificate", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QdCECertificate", "Yes", e.target.checked)} /> CE Certificate
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2 pt-1">
                                                        <input type="checkbox" checked={props.getFieldValue("evaluationForm", "QDEquivalentCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "QDEquivalentCertificate", "Yes", e.target.checked)} /> Equivalent Certificate or Others:
                                                    </div>
                                                    <div className="flex-grow-1 w-100">
                                                        <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                            value={props.getFieldValueText("evaluationForm", "QDEquivalentCertificateText")}
                                                            onChange={e => props.setFieldValueText("evaluationForm", "QDEquivalentCertificateText", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={7}>Test Report</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"15%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"35%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"13%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"13%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"14%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Validity of the Test Report</td>
                                        <td>Is the test report valid or not?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportValid", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportValid", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td colSpan={3}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportValid", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportValid", "No", e.target.checked)} /> No, if it is no, TÜV AUSTRIA will perform testing activities.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} rowSpan={3}>Test Standard</td>
                                        <td colSpan={2}>The test standard has been mentioned in related TR</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardMentioned", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardMentioned", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardMentioned", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardMentioned", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>The test standard is equivalent with the related standards in TR</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardEquivalent") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardEquivalent", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardEquivalent", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardEquivalent") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardEquivalent", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardEquivalent", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardEquivalent") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardEquivalent", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardEquivalent", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Verify the whole parameters and testing methods</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardParameters") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardParameters", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardParameters", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardParameters") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardParameters", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardParameters", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestStandardParameters") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestStandardParameters", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TestStandardParameters", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Test Laboratory</td>
                                        <td colSpan={5}>
                                            Please write name of the lab
                                            <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                value={props.getFieldValueText("evaluationForm", "TestLaboratory") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestLaboratory", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Lab information</td>
                                        <td>EN ISO/IEC 17025 accreditation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "LabInfoAccreditation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "LabInfoAccreditation", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "LabInfoAccreditation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "LabInfoAccreditation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "LabInfoAccreditation", "No")} onChange={e => props.setFieldValue("evaluationForm", "LabInfoAccreditation", "No", e.target.checked)} /> No
                                        </td>
                                        <td colSpan={2} className="check-col">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-2 pt-1">
                                                    <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "LabInfoAccreditation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "LabInfoAccreditation", "Other")} onChange={e => props.setFieldValue("evaluationForm", "LabInfoAccreditation", "Other", e.target.checked)} /> Other:
                                                </div>
                                                <div className="flex-grow-1 w-100">
                                                    <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                        value={props.getFieldValueText("evaluationForm", "LabInfoAccreditationText")}
                                                        onChange={e => props.setFieldValueText("evaluationForm", "LabInfoAccreditationText", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>&nbsp;</td>
                                        <td>Testing done by</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "LabInfoDoneBy") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "LabInfoDoneBy", "Own Lab")} onChange={e => props.setFieldValue("evaluationForm", "LabInfoDoneBy", "Own Lab", e.target.checked)} /> Own Lab
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "LabInfoDoneBy") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "LabInfoDoneBy", "Third Party Lab")} onChange={e => props.setFieldValue("evaluationForm", "LabInfoDoneBy", "Third Party Lab", e.target.checked)} /> Third Party Lab
                                        </td>
                                        <td colSpan={2} className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "LabInfoDoneBy") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "LabInfoDoneBy", "Subcontract")} onChange={e => props.setFieldValue("evaluationForm", "LabInfoDoneBy", "Subcontract", e.target.checked)} /> Subcontract<sup>5</sup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Subcontract Laboratory</td>
                                        <td colSpan={3}>Is the subcontracted laboratory included in TÜV AUSTRIA's laboratory list?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SubcontractedIncluded") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SubcontractedIncluded", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SubcontractedIncluded", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SubcontractedIncluded") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SubcontractedIncluded", "No")} onChange={e => props.setFieldValue("evaluationForm", "SubcontractedIncluded", "No", e.target.checked)} /> No<sup>6</sup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Acceptance of test reports from External Independent Laboratories</td>
                                        <td colSpan={5}>From a Third-Party laboratory having GAC or ILAC traceable ISO 17025 accreditation, scope/standards as applicable
                                            <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportAcceptance")}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportAcceptance", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} rowSpan={2}>Test samples requirement</td>
                                        <td colSpan={5}>Test sample models: to be determined by Test Laboratory  or TÜV AUSTRIA
                                        <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                value={props.getFieldValueText("evaluationForm", "TestSampleModel")}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestSampleModel", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>Number of test samples: to be determined by Test Laboratory or TÜV AUSTRIA
                                        <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                value={props.getFieldValueText("evaluationForm", "TestSampleModelNumber")}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestSampleModelNumber", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td>#</td>
                                        <td colSpan={6}>Verification of Test Report</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1</td>
                                        <td colSpan={4}>Are the standards and edition which are used correct? <a target="_blank" rel="noreferrer" href="https://saber.sa/home/regulations">https://saber.sa/home/regulations</a> Annex (1) of SASO Technical Regulation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardsCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardsCorrect", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "StandardsCorrect", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardsCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardsCorrect", "No")} onChange={e => props.setFieldValue("evaluationForm", "StandardsCorrect", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2</td>
                                        <td colSpan={4}>Model type, Technical data and marking label, correct?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ModelTypeCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ModelTypeCorrect", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ModelTypeCorrect", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ModelTypeCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ModelTypeCorrect", "No")} onChange={e => props.setFieldValue("evaluationForm", "ModelTypeCorrect", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">3</td>
                                        <td colSpan={4}>Are Test Report forms in line with the requirements/ standards?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportForms") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportForms", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportForms", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportForms") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportForms", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportForms", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">4</td>
                                        <td colSpan={4}>Is each test report signed by Approval personnel?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportSigned", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportSigned", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportSigned", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportSigned", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">5</td>
                                        <td colSpan={4}>Is the test report holding a valid Accreditation Logo?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportAccreditationLogo") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportAccreditationLogo", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportAccreditationLogo", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportAccreditationLogo") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportAccreditationLogo", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportAccreditationLogo", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">6</td>
                                        <td colSpan={4}>Plausibility of test requirements and verdicts? Acceptable according to TR requirements?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PlausibilityAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PlausibilityAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PlausibilityAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PlausibilityAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PlausibilityAcceptable", "No")} onChange={e => props.setFieldValue("evaluationForm", "PlausibilityAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">7</td>
                                        <td colSpan={4}>In case of not applying the standards listed in the TR; Verify National deviations and conditions checked and part of the test reports for the KSA?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "NationalDeviations") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "NationalDeviations", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "NationalDeviations", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "NationalDeviations") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "NationalDeviations", "No")} onChange={e => props.setFieldValue("evaluationForm", "NationalDeviations", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={5}>Other Quality Documents</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="index-col">1</td>
                                        <td>The certificate is acceptable</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "CertificateAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateAcceptable", "No")} onChange={e => props.setFieldValue("evaluationForm", "CertificateAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateAcceptable", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "CertificateAcceptable", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2</td>
                                        <td>The certificate scope is same with the Model Number in SABER platform</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateScope") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateScope", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "CertificateScope", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateScope") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateScope", "No")} onChange={e => props.setFieldValue("evaluationForm", "CertificateScope", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificateScope") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificateScope", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "CertificateScope", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={4}>2.2 Supplier Declaration of Conformity Form</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="index-col">1</td>
                                        <td>Supplier Declaration of Conformity shall be filled in on the company’s official papers / letterhead</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SupplierDeclarationFilled") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SupplierDeclarationFilled", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SupplierDeclarationFilled", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SupplierDeclarationFilled") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SupplierDeclarationFilled", "No")} onChange={e => props.setFieldValue("evaluationForm", "SupplierDeclarationFilled", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2</td>
                                        <td>Supplier Declaration of Conformity Form must be matched with related TR’s annex</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SupplierDeclarationMatched") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SupplierDeclarationMatched", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SupplierDeclarationMatched", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SupplierDeclarationMatched") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SupplierDeclarationMatched", "No")} onChange={e => props.setFieldValue("evaluationForm", "SupplierDeclarationMatched", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={4}>2.4 Photos</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="index-col">1</td>
                                        <td>Photos are visible, accessible, and easily readable</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosVisible") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosVisible", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PhotosVisible", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosVisible") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosVisible", "No")} onChange={e => props.setFieldValue("evaluationForm", "PhotosVisible", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2</td>
                                        <td>Photos will be used for verifying labelling and marking requirements</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>2.1</b> Labels on the product packaging shall be in accordance with the technical requirements contained in TR and the relevant standards</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelAccordance") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelAccordance", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelAccordance", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelAccordance") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelAccordance", "No")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelAccordance", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>2.2</b> Labels shall include information, warnings, operating instructions, and sales documents contained in related TR, and shall be written in clear script and in a way that is difficult to remove.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelInformation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelInformation", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelInformation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelInformation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelInformation", "No")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelInformation", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>2.3</b> All information used in the labelling shall be correct and proven</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelCorrect", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelCorrect", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelCorrect", "No")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelCorrect", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>2.4</b> Images and phrases used on the product packaging shall not violate the public order, public morals and Islamic values prevailing in the KSA. Acceptable?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelViolate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelViolate", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelViolate", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelViolate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelViolate", "No")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelViolate", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">3</td>
                                        <td>The dimensions, rates, design code or any related parameters should be traceable to “SI traceability<sup>7</sup>” requirements.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelTraceability") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelTraceability", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelTraceability", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PhotosLabelTraceability") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PhotosLabelTraceability", "No")} onChange={e => props.setFieldValue("evaluationForm", "PhotosLabelTraceability", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={6}>ISO Certification Requirements</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>Please select the quality management type if it is required by related TR</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "ISO 9001")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "ISO 9001", e.target.checked)} /> ISO 9001
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "ISO 14001")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "ISO 14001", e.target.checked)} /> ISO 14001
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "ISO 16949")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "ISO 16949", e.target.checked)} /> ISO 16949
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "ISO 45001")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "ISO 45001", e.target.checked)} /> ISO 45001
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "Quality Management System")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "Quality Management System", e.target.checked)} /> Quality Management System
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "Environmental Management System")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "Environmental Management System", e.target.checked)} /> Environmental Management System
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-2 pt-1">
                                                    <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "QualityManagementType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "QualityManagementType", "Other")} onChange={e => props.setFieldValue("evaluationForm", "QualityManagementType", "Other", e.target.checked)} /> Other then above:
                                                </div>
                                                <div className="flex-grow-1 w-100">
                                                    <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                        value={props.getFieldValueText("evaluationForm", "QualityManagementTypeText")}
                                                        onChange={e => props.setFieldValueText("evaluationForm", "QualityManagementTypeText", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>The Certification is still valid</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationStillValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationStillValid", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "CertificationStillValid", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationStillValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationStillValid", "No")} onChange={e => props.setFieldValue("evaluationForm", "CertificationStillValid", "No", e.target.checked)} /> No
                                        </td>
                                        <td colSpan={2}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationStillValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationStillValid", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "CertificationStillValid", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={7}>Factory Audit - FA (for Type3)</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={4}>
                                            <div>Is the FA required?</div>
                                            <div>&nbsp;</div>
                                            <div>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "FARequired") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "FARequired", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "FARequired", "Yes", e.target.checked)} /> Yes (fill the right table)
                                            </div>
                                            <div>&nbsp;</div>
                                            <div>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "FARequired") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "FARequired", "No")} onChange={e => props.setFieldValue("evaluationForm", "FARequired", "No", e.target.checked)} /> No
                                            </div>
                                        </td>
                                        <td>
                                            <input type="checkbox" checked={props.getFieldValue("evaluationForm", "NewFactory", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "NewFactory", "Yes", e.target.checked)} /> New Factory
                                        </td>
                                        <td colSpan={3}>
                                            <div>
                                                <input type="checkbox" checked={props.getFieldValue("evaluationForm", "ExistingClient", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ExistingClient", "Yes", e.target.checked)} /> Existing Client
                                            </div>
                                            <div>
                                                <div style={{float:"left"}}>
                                                    <input type="checkbox" checked={props.getFieldValue("evaluationForm", "FAAvailable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "FAAvailable", "Yes", e.target.checked)} /> FA Available
                                                </div>
                                                <div style={{float:"left", paddingLeft:10}}>
                                                    <input type="checkbox" checked={props.getFieldValue("evaluationForm", "SurveillanceAvailable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SurveillanceAvailable", "Yes", e.target.checked)} /> Surveillance Available
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <input type="checkbox" checked={props.getFieldValue("evaluationForm", "FactoryAuditReportAvailable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "FactoryAuditReportAvailable", "Yes", e.target.checked)} /> Factory audit report available for other certified product?
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Issue date of the FA report:</td>
                                        <td colSpan={3}>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2">
                                                        <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                            value={props.getFieldValueText("evaluationForm", "FactoryAuditReportDate")}
                                                            onChange={e => props.setFieldValueText("evaluationForm", "FactoryAuditReportDate", e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1 w-100 pt-1">
                                                        <input type="checkbox" checked={props.getFieldValue("evaluationForm", "FactoryAuditReportChecked", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "FactoryAuditReportChecked", "Yes", e.target.checked)} /> FM-GMAP-PC-AI-003c Factory Audit Report
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-2">
                                                        <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                            value={props.getFieldValueText("evaluationForm", "FactoryAuditSurveillanceDate")}
                                                            onChange={e => props.setFieldValueText("evaluationForm", "FactoryAuditSurveillanceDate", e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1 w-100 pt-1">
                                                    <input type="checkbox" checked={props.getFieldValue("evaluationForm", "FactoryAuditSurveillanceChecked", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "FactoryAuditSurveillanceChecked", "Yes", e.target.checked)} /> FM-GMAP-PC-AI-003d Factory Audit Surveillance
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Compliant with certification / Technical Regulation requirement?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CompliantCertificationRequirement") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CompliantCertificationRequirement", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "CompliantCertificationRequirement", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CompliantCertificationRequirement") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CompliantCertificationRequirement", "No")} onChange={e => props.setFieldValue("evaluationForm", "CompliantCertificationRequirement", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CompliantCertificationRequirement") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CompliantCertificationRequirement", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "CompliantCertificationRequirement", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={4}>Technical Documentation</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-2 pt-1">
                                                    <b>Technical Documentation No:</b>
                                                </div>
                                                <div className="flex-grow-1 w-100">
                                                    <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2)}
                                                        value={props.getFieldValueText("evaluationForm", "TechnicalDocumentationNo")}
                                                        onChange={e => props.setFieldValueText("evaluationForm", "TechnicalDocumentationNo", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td>#</td>
                                        <td colSpan={3}>Verification of Technical Documentation</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1</td>
                                        <td>Safety instructions, user manual, Label and Markings?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SafetyInstructionDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SafetyInstructionDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SafetyInstructionDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SafetyInstructionDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SafetyInstructionDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "SafetyInstructionDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2</td>
                                        <td>Risk analysis and assessment document from manufacturer</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "RiskAnalysisDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "RiskAnalysisDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "RiskAnalysisDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "RiskAnalysisDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "RiskAnalysisDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "RiskAnalysisDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">3</td>
                                        <td>Technical documentation shall include – at least - the following:</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.1</b> A general description of the product</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "GeneralDescriptionDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "GeneralDescriptionDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "GeneralDescriptionDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "GeneralDescriptionDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "GeneralDescriptionDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "GeneralDescriptionDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.2</b> Design, manufacturing drawings and horizontal projections (diagrams) for the product elements, units, divisions, and subdivisions, etc.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "DesignDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "DesignDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "DesignDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "DesignDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "DesignDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "DesignDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.3</b> Description and explanations, referred to therein, necessary to understand the drawings, diagrams, and the operation (use) of the product.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ExplanationDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ExplanationDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ExplanationDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ExplanationDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ExplanationDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "ExplanationDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.4</b> A list of the Saudi standards or any other relevant technical specifications adopted by SASO, whether fully or partially applied, and a description of the adopted solutions to meet the essential requirements of the Saudi technical regulations in case of non-application of the standards. In case of partial application of Saudi standards, the technical documentation shall clarify the applied clauses.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "StandardDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "StandardDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.5</b> Report results (graph calculations) of the design, operation control, conducted tests, etc.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ReportResultDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ReportResultDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ReportResultDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ReportResultDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ReportResultDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "ReportResultDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.6</b> Test reports</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.7</b> Representative samples of the planned production. TÜV AUSTRIA may request additional samples, if necessary.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SampleDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SampleDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "SampleDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "SampleDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "SampleDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "SampleDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">&nbsp;</td>
                                        <td><b>3.8</b> Evidence (proofs) supporting the appropriateness of the technical solutions applied in the design. Such evidence shall refer to all documents, particularly in case of non-application of the Saudi standards and/or the appropriate technical specification. Supporting evidence - as applicable - shall include results of test conducted in the suitable laboratory in the manufacturer or any other laboratory under the responsibility of manufacturer.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "EvidenceDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "EvidenceDocument", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "EvidenceDocument", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "EvidenceDocument") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "EvidenceDocument", "No")} onChange={e => props.setFieldValue("evaluationForm", "EvidenceDocument", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={3}>Result of Evaluation</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"50%"}}>Is the certification suggested as acceptable?</td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "CertificationAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "CertificationAcceptable", "No")} onChange={e => props.setFieldValue("evaluationForm", "CertificationAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Signature</td>
                                        <td>Date</td>
                                    </tr>
                                    <tr>
                                        <td>{(props.fboState && props.fboState.evaluationForm && props.fboState.evaluationForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td>
                                            {props.fboState && props.fboState.evaluationForm && props.fboState.evaluationForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.evaluationForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td>
                                            <MyDateRenderer value={(props.fboState && props.fboState.evaluationForm && props.fboState.evaluationForm.ticketForm && props.fboState.evaluationForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%", paddingTop:50}}>------------------------------------------</td>
                                        <td style={{width:"30%"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>1</sup> Please verify the existence of the relevant records before starting the evaluation. If there is any missing file in <b>“Records Check”</b> section, please connect with the sales team.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>2</sup> All evaluation process will be performed accordingly related CAI forms. CAI forms are accessible over server system.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>3</sup> Please refer existing certificate number for extension or re-issue.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>4</sup> If there is any missing information in <b>“Points to be Checked”</b> section, please connect with the sales team.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>5</sup> Please verify in the line below whether the laboratory is on TÜV AUSTRIA's approved list.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>6</sup> If the laboratory is not included in TÜV AUSTRIA's approved laboratory list or subcontractor list, the test report cannot be accepted without a positive laboratory evaluation. Please consult the quality department.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>7</sup> SI traceability' means 'metrological traceability to a unit of measurement in the International System of Units.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td colSpan={3}><hr/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"25%", textAlign:"left"}}>Status: Approved</td>
                                        <td style={{width:"50%", textAlign:"center"}}>FM-GMAP-PC-SS-001c Rev.: 00, 25.07.2023</td>
                                        <td style={{width:"25%", textAlign:"center"}}>Classification: Public</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default FormEvaluationSASO;