import { 
    KANBAN_START_LOADING, 
    KANBAN_END_LOADING, 
    SET_KANBAN_FILTER_SEARCH,
    SET_KANBAN_FILTER_SERVICE_ID,
    SET_KANBAN_FILTER_MINE_ONLY,
    KANBAN_DRAFT_LOADED, 
    KANBAN_NEW_LOADED,
    KANBAN_INPROGRESS_LOADED,
    KANBAN_SALES_ACTION_LOADED,
    KANBAN_PD_REVIEW_LOADED,
    KANBAN_PD_SUPPORT_LOADED,
    KANBAN_SAMPLING_LOADED,
    KANBAN_SCHEDULED_LOADED,
    KANBAN_WAITING_CORRECTIVE_ACTION_LOADED,
    KANBAN_PHASE_1_EVALUATION_LOADED,
    KANBAN_INSPECTION_LOADED,
    KANBAN_PHASE_2_EVALUATION_LOADED,
    KANBAN_FINAL_DECISION_LOADED,
    KANBAN_COMPLETED_LOADED,
    KANBAN_CLOSED_LOADED,
    KANBAN_STOPPED_LOADED,
    KANBAN_ABORTED_LOADED,
    KANBAN_GROUP_LOADED,
    KANBAN_GROUP_USER_LOADED,
    KANBAN_PD_GROUP_LOADED,
    KANBAN_PD_GROUP_USER_LOADED,
    KANBAN_USER_SEARCH_LOADED
} from "../constants/KanbanConstants";

const initialState = {
    loading: false,
    search: "",
    serviceId: 0,
    mineOnly: true,
    draftList: [],
    newList: [],
    inProgressList: [],
    salesActionList: [],
    pdReviewList: [],
    pdSupportList: [],
    samplingList: [],
    scheduledList: [],
    waitingCorrectiveActionList: [],
    phase1EvaluationList: [],
    inspectionList: [],
    phase2EvaluationList: [],
    finalDecisionList: [],
    completedList: [],
    closedList: [],
    stoppedList: [],
    abortedList: [],
    groupList: [],
    groupUserList: [],
    pdGroupList: [],
    pdGroupUserList: [],
    searchList: []
};

const KanbanReducer = (state = initialState, action) => {
    switch(action.type) {
        case KANBAN_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case KANBAN_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case SET_KANBAN_FILTER_SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case SET_KANBAN_FILTER_SERVICE_ID:
            return {
                ...state,
                serviceId: action.payload
            };
        case SET_KANBAN_FILTER_MINE_ONLY:
            return {
                ...state,
                mineOnly: action.payload
            };
        case KANBAN_DRAFT_LOADED:
            return {
                ...state,
                draftList: action.payload
            };
        case KANBAN_NEW_LOADED:
            return {
                ...state,
                newList: action.payload
            };
        case KANBAN_INPROGRESS_LOADED:
            return {
                ...state,
                inProgressList: action.payload
            };
        case KANBAN_SALES_ACTION_LOADED:
            return {
                ...state,
                salesActionList: action.payload
            };
        case KANBAN_PD_REVIEW_LOADED:
            return {
                ...state,
                pdReviewList: action.payload
            };
        case KANBAN_PD_SUPPORT_LOADED:
            return {
                ...state,
                pdSupportList: action.payload
            };
        case KANBAN_SAMPLING_LOADED:
            return {
                ...state,
                samplingList: action.payload
            };
        case KANBAN_SCHEDULED_LOADED:
            return {
                ...state,
                scheduledList: action.payload
            };
        case KANBAN_WAITING_CORRECTIVE_ACTION_LOADED:
            return {
                ...state,
                waitingCorrectiveActionList: action.payload
            };
        case KANBAN_PHASE_1_EVALUATION_LOADED:
            return {
                ...state,
                phase1EvaluationList: action.payload
            };
        case KANBAN_INSPECTION_LOADED:
            return {
                ...state,
                inspectionList: action.payload
            };
        case KANBAN_PHASE_2_EVALUATION_LOADED:
            return {
                ...state,
                phase2EvaluationList: action.payload
            };
        case KANBAN_FINAL_DECISION_LOADED:
            return {
                ...state,
                finalDecisionList: action.payload
            };
        case KANBAN_COMPLETED_LOADED:
            return {
                ...state,
                completedList: action.payload
            };
        case KANBAN_CLOSED_LOADED:
            return {
                ...state,
                closedList: action.payload
            };
        case KANBAN_STOPPED_LOADED:
            return {
                ...state,
                stoppedList: action.payload
            };
        case KANBAN_ABORTED_LOADED:
            return {
                ...state,
                abortedList: action.payload
            };
        case KANBAN_GROUP_LOADED:
            return {
                ...state,
                groupList: action.payload
            };
        case KANBAN_GROUP_USER_LOADED:
            return {
                ...state,
                groupUserList: action.payload
            };
        case KANBAN_PD_GROUP_LOADED:
            return {
                ...state,
                pdGroupList: action.payload
            };
        case KANBAN_PD_GROUP_USER_LOADED:
            return {
                ...state,
                pdGroupUserList: action.payload
            };
        case KANBAN_USER_SEARCH_LOADED:
            return {
                ...state,
                searchList: action.payload
            };
        default:
            return state;
    }
}

export default KanbanReducer;