import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Label, Input, Table } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import CardLoader from "../components/CardLoader";
import MySearchOption from "../components/MySearchOption";
import KanbanList from '../components/KanbanList';

import { useDispatch, useSelector } from "react-redux";
import * as kanbanActions from "../actions/KanbanActions";

const KanbanScreen  = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.kanban.loading);
    const search = useSelector((state) => state.kanban.search);
    const serviceId = useSelector((state) => state.kanban.serviceId);
    const serviceList = useSelector((state) => state.login.data.serviceList);
    const mineOnly = useSelector((state) => state.kanban.mineOnly);

    const draftList = useSelector((state) => state.kanban.draftList);
    const newList = useSelector((state) => state.kanban.newList);
    const inProgressList = useSelector((state) => state.kanban.inProgressList);
    const salesActionList = useSelector((state) => state.kanban.salesActionList);
    const pdReviewList = useSelector((state) => state.kanban.pdReviewList);
    const pdSupportList = useSelector((state) => state.kanban.pdSupportList);
    const samplingList = useSelector((state) => state.kanban.samplingList);
    const scheduledList = useSelector((state) => state.kanban.scheduledList);
    const waitingCorrectiveActionList = useSelector((state) => state.kanban.waitingCorrectiveActionList);
    const phase1EvaluationList = useSelector((state) => state.kanban.phase1EvaluationList);
    const inspectionList = useSelector((state) => state.kanban.inspectionList);
    const phase2EvaluationList = useSelector((state) => state.kanban.phase2EvaluationList);
    const finalDecisionList = useSelector((state) => state.kanban.finalDecisionList);
    const completedList = useSelector((state) => state.kanban.completedList);
    const closedList = useSelector((state) => state.kanban.closedList);
    const stoppedList = useSelector((state) => state.kanban.stoppedList);
    const abortedList = useSelector((state) => state.kanban.abortedList);
    const groupList = useSelector((state) => state.kanban.groupList);
    const groupUserList = useSelector((state) => state.kanban.groupUserList);
    const pdGroupList = useSelector((state) => state.kanban.pdGroupList);
    const pdGroupUserList = useSelector((state) => state.kanban.pdGroupUserList);
    const searchList = useSelector((state) => state.kanban.searchList);

    const user = useSelector((state) => state.login.user);

    const [fboSearch, setFBOSearch] = React.useState(search);
    const [fboServiceId, setFBOServiceId] = React.useState(serviceId);
    const [fboMineOnly, setFBOMineOnly] = React.useState(mineOnly);

    const [showDraft, setShowDraft] = React.useState(user.showDraft);
    const [showNew, setShowNew] = React.useState(user.showNew);
    const [showInProgress, setShowInProgress] = React.useState(user.showInProgress);
    const [showSalesAction, setShowSalesAction] = React.useState(user.showSalesAction);
    const [showPdReview, setShowPdReview] = React.useState(user.showPdReview);
    const [showPdSupport, setShowPdSupport] = React.useState(user.showPdSupport);
    const [showSampling, setShowSampling] = React.useState(user.showSampling);
    const [showScheduled, setShowScheduled] = React.useState(user.showScheduled);
    const [showWaitingCorrectiveAction, setShowWaitingCorrectiveAction] = React.useState(user.showWaitingCorrectiveAction);
    const [showPhase1Evaluation, setShowPhase1Evaluation] = React.useState(user.showPhase1Evaluation);
    const [showInspection, setShowInspection] = React.useState(user.showInspection);
    const [showPhase2Evaluation, setShowPhase2Evaluation] = React.useState(user.showPhase2Evaluation);
    const [showFinalDecision, setShowFinalDecision] = React.useState(user.showFinalDecision);
    const [showCompleted, setShowCompleted] = React.useState(user.showCompleted);
    const [showClosed, setShowClosed] = React.useState(user.showClosed);
    const [showStopped, setShowStopped] = React.useState(user.showStopped);
    const [showAborted, setShowAborted] = React.useState(user.showAborted);

    React.useEffect(() => {
		dispatch(kanbanActions.loadKanban("", fboSearch, fboServiceId, fboMineOnly));
    }, []);

    React.useEffect(() => {
		setFBOSearch(search);
    }, [search]);

    React.useEffect(() => {
		setFBOServiceId(serviceId);

        if(serviceId === "0") {
            setShowDraft(user.showDraft);
            setShowNew(user.showNew);
            setShowInProgress(user.showInProgress);
            setShowSalesAction(user.showSalesAction);
            setShowPdReview(user.showPdReview);
            setShowPdSupport(user.showPdSupport);
            setShowSampling(user.showSampling);
            setShowScheduled(user.showScheduled);
            setShowWaitingCorrectiveAction(user.showWaitingCorrectiveAction);
            setShowPhase1Evaluation(user.showPhase1Evaluation);
            setShowInspection(user.showInspection);
            setShowPhase2Evaluation(user.showPhase2Evaluation);
            setShowFinalDecision(user.showFinalDecision);
            setShowCompleted(user.showCompleted);
            setShowClosed(user.showClosed);
            setShowStopped(user.showStopped);
            setShowAborted(user.showAborted);
        }
        else if(serviceId === "1") { //PCOC
            setShowDraft(user.showDraft);
            setShowNew(user.showNew);
            setShowInProgress(user.showInProgress);
            setShowSalesAction(user.showSalesAction);
            setShowPdReview(user.showPdReview);
            setShowPdSupport(false);
            setShowSampling(false);
            setShowScheduled(false);
            setShowWaitingCorrectiveAction(false);
            setShowPhase1Evaluation(false);
            setShowInspection(false);
            setShowPhase2Evaluation(false);
            setShowFinalDecision(user.showFinalDecision);
            setShowCompleted(user.showCompleted);
            setShowClosed(user.showClosed);
            setShowStopped(user.showStopped);
            setShowAborted(user.showAborted);
        }
        else if(serviceId === "2") { //SCOC
            setShowDraft(user.showDraft);
            setShowNew(user.showNew);
            setShowInProgress(user.showInProgress);
            setShowSalesAction(user.showSalesAction);
            setShowPdReview(false);
            setShowPdSupport(false);
            setShowSampling(false);
            setShowScheduled(false);
            setShowWaitingCorrectiveAction(false);
            setShowPhase1Evaluation(false);
            setShowInspection(false);
            setShowPhase2Evaluation(false);
            setShowFinalDecision(false);
            setShowCompleted(user.showCompleted);
            setShowClosed(user.showClosed);
            setShowStopped(user.showStopped);
            setShowAborted(user.showAborted);
        }
        else if(serviceId === "3") { //REVW
            setShowDraft(user.showDraft);
            setShowNew(user.showNew);
            setShowInProgress(user.showInProgress);
            setShowSalesAction(user.showSalesAction);
            setShowPdReview(user.showPdReview);
            setShowPdSupport(false);
            setShowSampling(false);
            setShowScheduled(false);
            setShowWaitingCorrectiveAction(false);
            setShowPhase1Evaluation(false);
            setShowInspection(false);
            setShowPhase2Evaluation(false);
            setShowFinalDecision(false);
            setShowCompleted(user.showCompleted);
            setShowClosed(user.showClosed);
            setShowStopped(user.showStopped);
            setShowAborted(user.showAborted);
        }
        else if(serviceId === "4") { //FA
            setShowDraft(user.showDraft);
            setShowNew(user.showNew);
            setShowInProgress(user.showInProgress);
            setShowSalesAction(user.showSalesAction);
            setShowPdReview(false);
            setShowPdSupport(false);
            setShowSampling(false);
            setShowScheduled(user.showScheduled);
            setShowWaitingCorrectiveAction(user.showWaitingCorrectiveAction);
            setShowPhase1Evaluation(false);
            setShowInspection(false);
            setShowPhase2Evaluation(false);
            setShowFinalDecision(user.showFinalDecision);
            setShowCompleted(user.showCompleted);
            setShowClosed(user.showClosed);
            setShowStopped(user.showStopped);
            setShowAborted(user.showAborted);
        }
        else if(serviceId === "5") { //COSMETIC
            setShowDraft(user.showDraft);
            setShowNew(false);
            setShowInProgress(false);
            setShowSalesAction(user.showSalesAction);
            setShowPdReview(user.showPdReview);
            setShowPdSupport(user.showPdSupport);
            setShowSampling(user.showSampling);
            setShowScheduled(false);
            setShowWaitingCorrectiveAction(false);
            setShowPhase1Evaluation(user.showPhase1Evaluation);
            setShowInspection(user.showInspection);
            setShowPhase2Evaluation(user.showPhase2Evaluation);
            setShowFinalDecision(user.showFinalDecision);
            setShowCompleted(user.showCompleted);
            setShowClosed(user.showClosed);
            setShowStopped(user.showStopped);
            setShowAborted(user.showAborted);
        }
    }, [serviceId]);

    React.useEffect(() => {
		setFBOMineOnly(mineOnly);
    }, [mineOnly]);

    const onChangeServiceId = (value) => {
        setFBOServiceId(value);
        
        dispatch(kanbanActions.loadKanban("", fboSearch, value, fboMineOnly));
        
        dispatch(kanbanActions.setKanbanFilterSearch(fboSearch));
        dispatch(kanbanActions.setKanbanFilterServiceId(value));
        dispatch(kanbanActions.setKanbanFilterMineOnly(fboMineOnly));
    }

    const searchTickets = () => {
        dispatch(kanbanActions.loadKanban("", fboSearch, fboServiceId, fboMineOnly));

        dispatch(kanbanActions.setKanbanFilterSearch(fboSearch));
        dispatch(kanbanActions.setKanbanFilterServiceId(fboServiceId));
        dispatch(kanbanActions.setKanbanFilterMineOnly(fboMineOnly));
    }

    const showMyTicketsOnly = () => {
        if(user && (user.isReporter || user.isTechnicalUser || user.isPdReviewer || user.isDecisionMaker)) {
            return true;
        }

        return false;
    }

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-sm-0">TICKET LIST</h4>
                                    </div>
                                    <div className="flex-shrink-0">
                                        {!showDraft && draftList.length > 0 && <span className="badge bg-dark ms-2">DRAFT: {draftList.length}</span>}
                                        {!showNew && newList.length > 0 && <span className="badge bg-primary ms-2">NEW: {newList.length}</span>}
                                        {!showInProgress && inProgressList.length > 0 && <span className="badge bg-warning ms-2">IP: {inProgressList.length}</span>}
                                        {!showSalesAction && salesActionList.length > 0 && <span className="badge bg-info ms-2">SALES: {salesActionList.length}</span>}
                                        {!showPdReview && pdReviewList.length > 0 && <span className="badge bg-pd-review ms-2">PD: {pdReviewList.length}</span>}
                                        {!showPdSupport && pdSupportList.length > 0 && <span className="badge bg-pd-support ms-2">PD: {pdSupportList.length}</span>}
                                        {!showSampling && samplingList.length > 0 && <span className="badge bg-sampling ms-2">SAMPLE: {samplingList.length}</span>}
                                        {!showScheduled && scheduledList.length > 0 && <span className="badge bg-scheduled ms-2">SCH: {scheduledList.length}</span>}
                                        {!showWaitingCorrectiveAction && waitingCorrectiveActionList.length > 0 && <span className="badge bg-waiting-corrective-action ms-2">WAIT: {waitingCorrectiveActionList.length}</span>}
                                        {!showPhase1Evaluation && phase1EvaluationList.length > 0 && <span className="badge bg-phase-1 ms-2">PHASE 1: {phase1EvaluationList.length}</span>}
                                        {!showInspection && inspectionList.length > 0 && <span className="badge bg-inspection ms-2">INS: {inspectionList.length}</span>}
                                        {!showPhase2Evaluation && phase2EvaluationList.length > 0 && <span className="badge bg-phase-2 ms-2">PHASE 1: {phase2EvaluationList.length}</span>}
                                        {!showFinalDecision && finalDecisionList.length > 0 && <span className="badge bg-final-decision ms-2">FINAL: {finalDecisionList.length}</span>}
                                        {!showCompleted && completedList.length > 0 && <span className="badge bg-complete ms-2">COMPLETE: {completedList.length}</span>}
                                        {!showClosed && closedList.length > 0 && <span className="badge bg-success ms-2">CLOSE: {closedList.length}</span>}
                                        {!showStopped && stoppedList.length > 0 && <span className="badge bg-danger ms-2">STOP: {stoppedList.length}</span>}
                                        {!showAborted && abortedList.length > 0 && <span className="badge bg-secondary ms-2">ABORT: {abortedList.length}</span>}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-2">
                                        <select className="form-select"
                                            value={fboServiceId}
                                            onChange={e => onChangeServiceId(e.target.value)}
                                        >
                                            <option value={0}>All</option>
                                            {serviceList && serviceList.map(function(item, index){
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <MySearchOption
                                        placeholder={"Search for tickets..."}
                                        value={fboSearch}
                                        searchList={searchList || []}
                                        fieldName=""
                                        onChange={setFBOSearch}
                                    />
                                    <div className={`form-check pt-2 ms-2 me-2 ${showMyTicketsOnly() ? "" : "d-none"}`}>
                                        <input type="checkbox" className="form-check-input"
                                            checked={fboMineOnly}
                                            onChange={e => setFBOMineOnly(e.target.checked)}
                                        /> My Tickets Only
                                    </div>
                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Search Tickets"
                                        onClick={() => searchTickets()}
                                    >
                                        <i className="ri-search-line" />
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <div className="tasks-board mb-3" id="kanbanboard">
                            <CardLoader loading={loading} />
                            {showDraft && <KanbanList title="Draft" titleClass="bg-dark" borderClass="border-dark" list={draftList} groupList={groupList} groupUserList={groupUserList} pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showNew && <KanbanList title="New" titleClass="bg-primary" borderClass="border-primary" list={newList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showInProgress && <KanbanList title="In Progress" titleClass="bg-warning" borderClass="border-warning" list={inProgressList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showSalesAction && <KanbanList title="Sales Action" titleClass="bg-info" borderClass="border-info" list={salesActionList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showPdReview && <KanbanList title="PD Review" titleClass="bg-pd-review" borderClass="border-pd-review" list={pdReviewList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showPdSupport && <KanbanList title="PD Support" titleClass="bg-pd-support" borderClass="border-pd-support" list={pdSupportList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showSampling && <KanbanList title="Sampling" titleClass="bg-sampling" borderClass="border-sampling" list={samplingList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showScheduled && <KanbanList title="Scheduled" titleClass="bg-scheduled" borderClass="border-scheduled" list={scheduledList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showWaitingCorrectiveAction && <KanbanList title="Waiting Corrective Action" titleClass="bg-waiting-corrective-action" borderClass="border-waiting-corrective-action" list={waitingCorrectiveActionList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showPhase1Evaluation && <KanbanList title="Phase 1 Evaluation" titleClass="bg-phase-1" borderClass="border-phase-1" list={phase1EvaluationList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showInspection && <KanbanList title="Inspection" titleClass="bg-inspection" borderClass="border-inspection" list={inspectionList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showPhase2Evaluation && <KanbanList title="Phase 2 Evaluation" titleClass="bg-phase-2" borderClass="border-phase-2" list={phase2EvaluationList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showFinalDecision && <KanbanList title="Final Decision" titleClass="bg-final-decision" borderClass="border-final-decision" list={finalDecisionList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showCompleted && <KanbanList title="Completed (Pending Invoice)" titleClass="bg-complete" borderClass="border-complete" list={completedList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showClosed && <KanbanList title="Closed" titleClass="bg-success" borderClass="border-success" list={closedList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showStopped && <KanbanList title="Stopped" titleClass="bg-danger" borderClass="border-danger" list={stoppedList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                            {showAborted && <KanbanList title="Aborted" titleClass="bg-secondary" borderClass="border-secondary" list={abortedList} groupList={groupList} groupUserList={groupUserList}  pdGroupList={pdGroupList} pdGroupUserList={pdGroupUserList} />}
                        </div>
                    </Row>                
                </Container>
            </div>
        </Fragment>
    );
}

export default KanbanScreen;