import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Label, Input, Row, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as isoRequestActions from "../actions/IsoRequestActions";

const RelatedIsoRequestList = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.iso.loadingRelated);
    const data = useSelector((state) => state.iso.relatedList);
    const user = useSelector((state) => state.login.user);
    
    React.useEffect(() => {
		dispatch(isoRequestActions.loadRelatedIsoRequest(props.ticketId, props.customerId));
    }, [props.ticketId, props.customerId]);

    return (
        <Fragment>
            <Row className="gy-2">
                <CardLoader loading={loading} />
                <Col xxl={12} md={12}>
                    <Table className="table-hover table-bordered align-middle mb-0">
                        <thead className="table-primary">
                            <tr>
                                <th>Ticket #</th>
                                <th>Request Type</th>
                                <th>Status</th>
                                <th>Certificate #</th>
                                {user.isLinkedToCrm && <th>CRM Invoice #</th>}
                                {user.isLinkedToCrm && <th>Tax Invoice #</th>}
                                {user.isLinkedToCrm && <th>Reference #</th>}
                                <th>Action Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length == 0 && <tr><td colSpan={7}>No Ticket(s)</td></tr>}
                            {data.map(function(item, index){
                                return(
                                    <tr key={index}>
                                        <td>
                                            <NavLink  to={`/request-edit/${item.id}`}>{item.requestNumber}</NavLink>
                                        </td>
                                        <td>{item.requestType}</td>
                                        <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                        <td>{item.certificateNumber}</td>
                                        {user.isLinkedToCrm && <td>{item.crmInvoiceNumber}</td>}
                                        {user.isLinkedToCrm && <td>{item.taxInvoiceNumber}</td>}
                                        {user.isLinkedToCrm && <td>{item.referenceNumber}</td>}
                                        <td><MyDateRenderer value={item.lastActionDate} /></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RelatedIsoRequestList;