import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ConfirmModal = ({ message, show, onYesClick, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-3">
        <div className="mt-2 text-center">
          <div className="pt-2 fs-15">
            <h4>Confirmation</h4>
            <p className="text-muted mb-0" style={{whiteSpace:"no-wrap"}}>{message}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn btn-primary btn-label w-md" onClick={onYesClick}>
            <i className="ri-check-line label-icon align-bottom"></i> Yes
          </button>
          <button type="button" className="btn btn-dark btn-label w-md" data-bs-dismiss="modal" onClick={onCloseClick}>
            <i className="ri-close-line label-icon align-bottom"></i> No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;