import { INSPECTOR_SEARCH_START_LOADING, INSPECTOR_SEARCH_END_LOADING, INSPECTOR_SEARCH_LOADED, SET_INSPECTOR_FILTER } from "../constants/InspectorConstants";
import { INSPECTOR_START_LOADING, INSPECTOR_END_LOADING, INSPECTOR_LOADED } from "../constants/InspectorConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadInspectorSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: INSPECTOR_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Inspector/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: INSPECTOR_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: INSPECTOR_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: INSPECTOR_SEARCH_END_LOADING });
        }
    };
}

export function setInspectorFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_INSPECTOR_FILTER,
            payload: filter
        });
    };
}

export function loadInspector(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: INSPECTOR_START_LOADING });

        try {

            var data = await getApiData("Inspector/GetInspectorFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: INSPECTOR_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: INSPECTOR_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: INSPECTOR_END_LOADING });
        }
    };
}

export function saveInspector(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: INSPECTOR_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Inspector/SaveInspectorFBO", null, fbo);
            
            if(data.success) {
                await dispatch({
                    type: INSPECTOR_LOADED,
                    payload: data.responseEntities[0]
                });
                
                dispatch(commonActions.setSuccessMessage("Inspector Saved Successfully!"));
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: INSPECTOR_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: INSPECTOR_END_LOADING });
        }
    };
}