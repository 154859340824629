import { ISO_REQUEST_SEARCH_START_LOADING, ISO_REQUEST_SEARCH_END_LOADING, ISO_REQUEST_SEARCH_LOADED, SET_ISO_REQUEST_FILTER } from "../constants/IsoRequestConstants";
import { ISO_REQUEST_NOTIFY_START_LOADING, ISO_REQUEST_NOTIFY_END_LOADING, ISO_REQUEST_NOTIFY_LOADED, SET_ISO_REQUEST_NOTIFY_FILTER } from "../constants/IsoRequestConstants";
import { ISO_REQUEST_START_LOADING, ISO_REQUEST_END_LOADING, ISO_REQUEST_LOADED } from "../constants/IsoRequestConstants";
import { RELATED_ISO_REQUEST_START_LOADING, RELATED_ISO_REQUEST_END_LOADING, RELATED_ISO_REQUEST_LOADED } from "../constants/IsoRequestConstants";
import { ISO_REQUEST_CERTIFICATE_START_LOADING, ISO_REQUEST_CERTIFICATE_END_LOADING, ISO_REQUEST_CERTIFICATE_LOADED } from "../constants/IsoRequestConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadIsoRequestSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("IsoRequest/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_REQUEST_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_SEARCH_END_LOADING });
        }
    };
}

export function setIsoRequestFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_ISO_REQUEST_FILTER,
            payload: filter
        });
    };
}

export function loadIsoRequestNotifySearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_NOTIFY_START_LOADING });

        try {

            var data = await postApiJsonData("IsoRequest/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_NOTIFY_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_REQUEST_NOTIFY_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_NOTIFY_END_LOADING });
        }
    };
}

export function setIsoRequestNotifyFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_ISO_REQUEST_NOTIFY_FILTER,
            payload: filter
        });
    };
}

export function loadIsoRequest(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_START_LOADING });

        try {

            var data = await getApiData("IsoRequest/GetIsoRequestFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                    dispatch(commonActions.setErrorMessage(data.errorDesc));
                }
                else if(data.errorCode == "402") {
                    if(data.companyId && data.companyId > 0) {
                        navigate('/invalid-company/iso/' + data.companyId + "/" + data.moduleName + "/" + id);
                    }
                    else {
                        navigate('/invalid-company');
                        dispatch(commonActions.setErrorMessage(data.errorDesc));
                    }
                }
                else {
                    dispatch(commonActions.setErrorMessage(data.errorDesc));
                }
            }
            
			await dispatch({ type: ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_END_LOADING });
        }
    };
}

export function saveIsoRequest(fbo, status, message, invoice, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("IsoRequest/SaveIsoRequestFBO", { status, message }, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/iso-request-edit/' + data.responseEntities[0].isoRequest.id);
                    dispatch(commonActions.setSuccessMessage("ISO Ticket Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: ISO_REQUEST_LOADED,
                        payload: data.responseEntities[0]
                    });
                    
                    if(invoice) {
                        //TODO:
                        //dispatch(createInvoice(fbo.isoRequest.id, navigate));
                    }
                    else {
                        dispatch(commonActions.setSuccessMessage("ISO Ticket Saved Successfully!"));
                    }
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_END_LOADING });
        }
    };
}

export function assignMeIsoRequest(isoRequestId, userId, assignMe, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_START_LOADING });

        try {

            var data = await postApiJsonData("IsoRequest/AssignIsoRequest", { isoRequestId, userId, assignMe }, null);
            
            if(data.success) {
                dispatch(loadIsoRequest(isoRequestId, navigate));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_END_LOADING });
        }
    };
}

export async function markProceedWithoutPayment(isoRequestId, commentText) {
    var returnData = {
        success: false,
        errorMessage: "",
        successMessage: "ISO Ticket maked to Proceed without Payment Successfully!"
    };

    try {

        var data = await getApiData("IsoRequest/MarkProceedWithoutPayment", { isoRequestId, commentText });

        if(data.success) {
            returnData.success = true;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export async function getIsoCertificate(certificateNumber) {
    var returnData = {
        success: false,
        errorMessage: "",
        certificate: null
    };

    try {

        var data = await getApiData("IsoCertificate/GetIsoCertificate", { certificateNumber });

        if(data.success) {
            returnData.success = true;
            returnData.certificate = data.responseEntities[0];
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export async function generateCertificate(isoRequestId, commentText) {
    var returnData = {
        success: false,
        errorMessage: "",
        successMessage: "ISO Ticket Certificate Generated Successfully!"
    };

    try {

        var data = await getApiData("IsoRequest/GenerateCertificate", { isoRequestId, commentText });

        if(data.success) {
            returnData.success = true;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export async function getCertificate(isoRequestId) {
    var returnData = {
        success: false,
        errorMessage: "",
        list: []
    };

    try {

        var data = await getApiData("IsoRequest/GetCertificate", { isoRequestId }, false);
        
        if(data.success) {
            returnData.success = true;
            returnData.list = data.responseEntities;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export function loadRelatedIsoRequest(ticketId, customerId) {
    return async (dispatch) => {
        
        await dispatch({ type: RELATED_ISO_REQUEST_START_LOADING });

        try {

            var data = await getApiData("IsoRequest/GetRelatedRequests", { ticketId, customerId });
            
            if(data.success) {
                await dispatch({
                    type: RELATED_ISO_REQUEST_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: RELATED_ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: RELATED_ISO_REQUEST_END_LOADING });
        }
    };
}

export function loadIsoCertificate(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_CERTIFICATE_START_LOADING });

        try {

            var data = await getApiData("IsoCertificate/GetIsoCertificateFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_CERTIFICATE_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                    dispatch(commonActions.setErrorMessage(data.errorDesc));
                }
                else {
                    dispatch(commonActions.setErrorMessage(data.errorDesc));
                }
            }
            
			await dispatch({ type: ISO_REQUEST_CERTIFICATE_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_CERTIFICATE_END_LOADING });
        }
    };
}

export function saveIsoCertificate(fbo) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_CERTIFICATE_START_LOADING });

        try {

            var data = await postApiJsonData("IsoCertificate/SaveIsoCertificateFBO", { }, fbo);
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_CERTIFICATE_LOADED,
                    payload: data.responseEntities[0]
                });
                    
                dispatch(commonActions.setSuccessMessage("ISO Certificate Saved Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: ISO_REQUEST_CERTIFICATE_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_CERTIFICATE_END_LOADING });
        }
    };
}