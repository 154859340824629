//Search
export const INSPECTOR_SEARCH_START_LOADING = "INSPECTOR_SEARCH_START_LOADING";
export const INSPECTOR_SEARCH_END_LOADING = "INSPECTOR_SEARCH_END_LOADING";
export const INSPECTOR_SEARCH_LOADED = "INSPECTOR_SEARCH_LOADED";
export const SET_INSPECTOR_FILTER = "SET_INSPECTOR_FILTER";

//Edit
export const INSPECTOR_START_LOADING = "INSPECTOR_START_LOADING";
export const INSPECTOR_END_LOADING = "INSPECTOR_END_LOADING";
export const INSPECTOR_LOADED = "INSPECTOR_LOADED";
