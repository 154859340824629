import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Collapse, Table } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import Lookup from "../components/Lookup";
import CustomerLookup from "../components/CustomerLookup";
import RelatedIsoRequestList from "../components/RelatedIsoRequestList";
import MyDateInput from "../components/MyDateInput";
import { Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import ConfirmModal from "../components/ConfirmModal";
import ConfirmModalAdvanced from "../components/ConfirmModalAdvanced";
import IsoServiceDocumentGrid from "../components/IsoServiceDocumentGrid";
import CommentGrid from "../components/CommentGrid";
import MyDateRenderer from "../components/MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as isoRequestActions from "../actions/IsoRequestActions";
import * as commonActions from "../actions/CommonActions";

const IsoRequestEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.iso.loadingFBO);
    const user = useSelector((state) => state.login.user);
    const fbo = useSelector((state) => state.iso.fbo);

    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedReporter, setSelectedReporter] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [selectedAuditor, setSelectedAuditor] = React.useState([]);
    const [invoiceInformationCollapsed, setInvoiceInformationCollapsed] = React.useState(true);
    const [relatedIsoRequestCollapsed, setRelatedIsoRequestCollapsed] = React.useState(true);
    const [selectedManDayStandardFBO, setSelectedManDayStandardFBO] = React.useState(null);

    const [loadingLocal, setLoadingLocal] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = React.useState("");
    const [currentMessage, setCurrentMessage] = React.useState("");
    const [showAssignMeModal, setShowAssignMeModal] = React.useState(false);
    const [showSubmitModal, setShowSubmitModal] = React.useState(false);
    const [showSalesActionModal, setShowSalesActionModal] = React.useState(false);
    const [showCalculateManDaysModal, setShowCalculateManDaysModal] = React.useState(false);
    const [showSalesSupportModal, setShowSalesSupportModal] = React.useState(false);
    const [showCustomerApprovedModal, setShowCustomerApprovedModal] = React.useState(false);
    const [showAuditInProgressModal, setShowAuditInProgressModal] = React.useState(false);
    const [showTechnicalReviewModal, setShowTechnicalReviewModal] = React.useState(false);
    const [showFinalDecisionModal, setShowFinalDecisionModal] = React.useState(false);
    const [showCompletedModal, setShowCompletedModal] = React.useState(false);
    const [showClosedModal, setShowClosedModal] = React.useState(false);
    const [showAbortedModal, setShowAbortedModal] = React.useState(false);
    const [showResetToDraftModal, setShowResetToDraftModal] = React.useState(false);
    const [showProceedWithoutPaymentModal, setShowProceedWithoutPaymentModal] = React.useState(false);
    const [showGenerateCertificateModal, setShowGenerateCertificateModal] = React.useState(false);
    
    React.useEffect(() => {
	    dispatch(isoRequestActions.loadIsoRequest(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedReporter([]);
        setSelectedCustomer([]);
        setSelectedAuditor([]);
        
        if(fbo) {
            reset(fbo.IsoRequest);
            setSelectedManDayStandardFBO(fbo.manDayStandardFBO);

            if(fbo.reporter != null) {
                setSelectedReporter(fbo.reporter);
            }

            if(fbo.customer != null) {
                setSelectedCustomer([fbo.customer]);
            }

            if(fbo.auditor != null) {
                setSelectedAuditor([fbo.auditor]);
            }
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onChangeDocument = (isProduct) => {
        setRefreshAction(!refreshAction);
    }

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const getFormattedDateTime = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy HH:MM");
        }
        return "";
    }

    const getDateWithoutTimezone = (value) => {
        if(value) {
            const tzoffset = value.getTimezoneOffset() * 60000;
            const withoutTimezone = new Date(value.valueOf() - tzoffset).toISOString().slice(0, -1);
            return withoutTimezone;
        }
        return "";
    }

    const onIsoRequestChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            if(name === "complexityValue") {
                value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            }

            copy_fboState.isoRequest[name] = value;
        }
        else if(value === false) {
            copy_fboState.isoRequest[name] = false;
        }
        else {
            copy_fboState.isoRequest[name] = null;
        }

        if(name === "crmName" || name === "crmCountry") {
            copy_fboState.isoRequest["customerId"] = null;
            setSelectedCustomer([]);
        }

        if(name === "companyName" || name === "isHistoryData") {
            var companyName = copy_fboState.isoRequest.companyName;
            var isHistoryData = copy_fboState.isoRequest.isHistoryData;

            if(companyName === "Europe" || isHistoryData === "Yes") {
                copy_fboState.canCalculateManDays = false;
                copy_fboState.canCompleted = true;
            }
            else {
                copy_fboState.canCalculateManDays = true;
                copy_fboState.canCompleted = false;
                copy_fboState.canClosed = false;
            }
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onIsoCertificateChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.isoRequest.certificate[name] = value;
        }
        else if(value === false) {
            copy_fboState.isoRequest.certificate[name] = false;
        }
        else {
            copy_fboState.isoRequest.certificate[name] = null;
        }

        if(name === "certificateIssueDate") {
            if(value) {
                copy_fboState.isoRequest.certificate["certificateIssueDate"] = getDateWithoutTimezone(value);
                copy_fboState.isoRequest.certificate["surveillance1Date"] = getDateWithoutTimezone(new Date(moment(value).add(9,'M').subtract(1, 'days')));
                copy_fboState.isoRequest.certificate["surveillance2Date"] = getDateWithoutTimezone(new Date(moment(value).add(21,'M').subtract(1, 'days')));
                copy_fboState.isoRequest.certificate["certificateExpiryDate"] = getDateWithoutTimezone(new Date(moment(value).add(3,'y').subtract(1, 'days')));
            }
            else {
                copy_fboState.isoRequest.certificate["surveillance1Date"] = null;
                copy_fboState.isoRequest.certificate["surveillance2Date"] = null;
                copy_fboState.isoRequest.certificate["certificateExpiryDate"] = null;
            }

            copy_fboState.showCertificateLabel = false;
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onReporterChange = (items) => {
        setSelectedReporter(items);
        
        if(items.length > 0) {
            var ids = [];
            
            items.forEach(element => {
                ids.push(element.id);
            });

            onIsoRequestChange("reporterUserId", ids.toString());
        }
        else {
            onIsoRequestChange("reporterUserId", null);
        }
    }

    const isCustomerReadonly = () => {
        if(fboState && (fboState.isoRequest.crmInvoiceId || fboState.isoRequest.status === "Closed")) {
            return true;
        }

        return false;
    }

    const onCustomerChange = (item) => {
        setSelectedCustomer(item);
        
        if(item.length > 0) {
            onIsoRequestChange("customerId", item[0].id.toString());
        }
        else {
            onIsoRequestChange("customerId", null);
        }
    }

    const onAuditorChange = (item) => {
        setSelectedAuditor(item);
        var copy_fboState = createDeepClone(fboState);
        
        if(item.length > 0) {
            onIsoRequestChange("auditUserId", item[0].id.toString());
        }
        else {
            onIsoRequestChange("auditUserId", null);
        }
    }

    const onSaveIsoRequest = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(isoRequestActions.saveIsoRequest(fboState, selectedStatus, currentMessage, false, navigate));
        }

        onCloseModalClick();
    }

    const onCancelIsoRequest = () => {
        setFBOState(fbo);

        if(fbo.reporter != null) {
            setSelectedReporter(fbo.reporter);
        }
        else {
            setSelectedReporter([]);
        }

        if(fbo.customer != null) {
            setSelectedCustomer([fbo.customer]);
        }
        else {
            setSelectedCustomer([]);
        }

        if(fbo.auditor != null) {
            setSelectedAuditor([fbo.auditor]);
        }
        else {
            setSelectedAuditor([]);
        }

        setIsDirty(false);
        clearErrors();
    }
    
    const onManDayStandardChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        var manDayStandardFBO = fbo.manDayStandardFBOList.find(item => item.manDayStandard.id == value);
        setSelectedManDayStandardFBO(manDayStandardFBO);

        copy_fboState.isoRequest[name] = value;
        copy_fboState.isoRequest["manDayStandardConfigurationId"] = null;
        copy_fboState.isoRequest["complexity"] = null;
        copy_fboState.isoRequest["complexityValue"] = null;

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onManDayStandardNumberOfPersonnelChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        copy_fboState.isoRequest[name] = value
        copy_fboState.isoRequest["complexity"] = null;
        copy_fboState.isoRequest["complexityValue"] = null;

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onManDayStandardComplexityChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        var complexityValue = null;

        if (selectedManDayStandardFBO.manDayStandardConfigurationList != null){
            var selectedManDayStandardConfiguration = selectedManDayStandardFBO.manDayStandardConfigurationList.find(item => item.id == fboState.isoRequest.manDayStandardConfigurationId);
            if (selectedManDayStandardConfiguration != null){
                if (value === 'Low') {
                    complexityValue = selectedManDayStandardConfiguration.complexityLow;
                } else if (value === 'Medium') {
                    complexityValue = selectedManDayStandardConfiguration.complexityMedium;
                } else if (value === 'High') {
                    complexityValue = selectedManDayStandardConfiguration.complexityHigh;
                }
            }
        }
        
        copy_fboState.isoRequest[name] = value
        copy_fboState.isoRequest["complexityValue"] = complexityValue;

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onLocationChange = (index, name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            if(name === "auditDate") {
                copy_fboState.isoRequest.locations[index][name] = getDateWithoutTimezone(value);
            }
            else {
                copy_fboState.isoRequest.locations[index][name] = value;
            }
        }
        else if(value === false) {
            copy_fboState.isoRequest.locations[index][name] = false;
        }
        else {
            copy_fboState.isoRequest.locations[index][name] = null;
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onLocationAdd = () => {
        var copy_fboState = createDeepClone(fboState);
        var copy_location = createDeepClone(fboState.location);
        
        copy_fboState.isoRequest.locations.push(copy_location);
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onAssignMeTicket = async () => {
        dispatch(isoRequestActions.assignMeIsoRequest(fboState.isoRequest.id, user.id, true, navigate));
        onCloseModalClick();
    }

    const onAssignMeTicketClick = () => {
        setShowAssignMeModal(true);
    }

    const onSubmitIsoRequetClick = () => {
        setSelectedStatus("New");
        setCurrentMessage("");
        setShowSubmitModal(true);
    }

    const onSalesActionIsoRequetClick = () => {
        setSelectedStatus("Sales Action");
        setCurrentMessage("");
        setShowSalesActionModal(true);
    }

    const onCalculateManDaysIsoRequetClick = () => {
        setSelectedStatus("Calculate Man Days");
        setCurrentMessage("");
        setShowCalculateManDaysModal(true);
    }

    const onSalesSupportIsoRequetClick = () => {
        setSelectedStatus("Sales Support");
        setCurrentMessage("");
        setShowSalesSupportModal(true);
    }

    const onCustomerApprovedIsoRequetClick = () => {
        setSelectedStatus("Customer Approved");
        setCurrentMessage("");
        setShowCustomerApprovedModal(true);
    }

    const onAuditInProgressIsoRequetClick = () => {
        setSelectedStatus("Audit In Progress");
        setCurrentMessage("");
        setShowAuditInProgressModal(true);
    }

    const onTechnicalReviewIsoRequetClick = () => {
        setSelectedStatus("Technical Review");
        setCurrentMessage("");
        setShowTechnicalReviewModal(true);
    }

    const onFinalDecisionIsoRequetClick = () => {
        setSelectedStatus("Final Decision");
        setCurrentMessage("");
        setShowFinalDecisionModal(true);
    }

    const onCompletedIsoRequetClick = () => {
        setSelectedStatus("Completed");
        setCurrentMessage("");
        setShowCompletedModal(true);
    }

    const onClosedIsoRequetClick = () => {
        setSelectedStatus("Closed");
        setCurrentMessage("");
        setShowClosedModal(true);
    }

    const onAbortedIsoRequetClick = () => {
        setSelectedStatus("Aborted");
        setCurrentMessage("");
        setShowAbortedModal(true);
    }

    const onResetToDraftIsoRequetClick = () => {
        setSelectedStatus("Draft");
        setCurrentMessage("");
        setShowResetToDraftModal(true);
    }

    const onCloseModalClick = () => {
        setSelectedStatus("");
        setCurrentMessage("");

        setShowAssignMeModal(false);
        setShowSubmitModal(false);
        setShowSalesActionModal(false);
        setShowCalculateManDaysModal(false);
        setShowSalesSupportModal(false);
        setShowCustomerApprovedModal(false);
        setShowAuditInProgressModal(false);
        setShowTechnicalReviewModal(false);
        setShowFinalDecisionModal(false);
        setShowCompletedModal(false);
        setShowClosedModal(false);
        setShowAbortedModal(false);
        setShowResetToDraftModal(false);
        setShowProceedWithoutPaymentModal(false);
        setShowGenerateCertificateModal(false);
    }

    const onProceedWithoutPayment = async () => {
        setShowProceedWithoutPaymentModal(true);
    }

    const onSaveProceedWithoutPayment = async () => {
        setShowProceedWithoutPaymentModal(false);

        setLoadingLocal(true);
        var data = await isoRequestActions.markProceedWithoutPayment(fboState.isoRequest.id, currentMessage);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(isoRequestActions.loadIsoRequest(fboState.isoRequest.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }

        setCurrentMessage("");
        reloadAction();
    }

    const onGenerateCertificate = async () => {
        setShowGenerateCertificateModal(true);
    }

    const onSaveGenerateCertificate = async () => {
        setShowGenerateCertificateModal(false);

        setLoadingLocal(true);
        var data = await isoRequestActions.generateCertificate(fboState.isoRequest.id, currentMessage);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(isoRequestActions.loadIsoRequest(fboState.isoRequest.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }

        setCurrentMessage("");
        reloadAction();
    }

    const UserRenderer = (results, menuProps, props) => (
        <Menu id="menuUserLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Name</div>
                    <div className="col">Email</div>
                    <div className="col">Employee ID</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.fullName || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.employeeId || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const AuditorRenderer = (results, menuProps, props) => (
        <Menu id="menuAuditorLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Auditor Name</div>
                    <div className="col">Email</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.fullName || ""}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ""}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Edit ISO Ticket"} />
                    <Row>
                        <Col lg={9}>
                            <CardLoader loading={loading || loadingLocal} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">ISO Ticket</h5>
                                    <div className="flex-shrink-1 ms-3">
                                        <div className="d-flex flex-wrap gap-2">
                                            {fboState && fboState.canSave &&
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveIsoRequest}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save ISO Ticket
                                            </button>
                                            }
                                            {fboState && fboState.canSave &&
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelIsoRequest}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                            }
                                            {fboState && fboState.canAssignMe &&
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading}
                                                onClick={() => onAssignMeTicketClick()}
                                            >
                                                <i className="ri-user-follow-line label-icon align-bottom"></i> Assign Me ISO Ticket
                                            </button>
                                            }
                                            {fboState && fboState.canSubmit &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onSubmitIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Submit
                                            </button>
                                            }
                                            {fboState && fboState.canSalesAction &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onSalesActionIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Sales Action
                                            </button>
                                            }
                                            {fboState && fboState.canCalculateManDays &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onCalculateManDaysIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> {fboState && fboState.isSalesSupport ? "Recalculate Man Days" : "Calculate Man Days"} 
                                            </button>
                                            }
                                            {fboState && fboState.canSalesSupport &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onSalesSupportIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Sales Support
                                            </button>
                                            }
                                            {fboState && fboState.canCustomerApproved &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onCustomerApprovedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Customer Approved
                                            </button>
                                            }
                                            {fboState && fboState.canAuditInProgress &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onAuditInProgressIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> {fboState && fboState.isTechnicalReview ? "Return to Audit" : "Send to Audit"}
                                            </button>
                                            }
                                            {fboState && fboState.canTechnicalReview &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onTechnicalReviewIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Technical Review
                                            </button>
                                            }
                                            {fboState && fboState.canFinalDecision &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onFinalDecisionIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Final Decision
                                            </button>
                                            }
                                            {fboState && fboState.canCompleted &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onCompletedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Complete
                                            </button>
                                            }
                                            {fboState && fboState.canClosed &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onClosedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Close
                                            </button>
                                            }
                                            {fboState && fboState.canAborted &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onAbortedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Abort
                                            </button>
                                            }
                                            {fboState && fboState.canResetToDraft &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onResetToDraftIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Reset To Draft
                                            </button>
                                            }
                                            {fboState && fboState.canProceed && 
                                            <button className="btn btn-dark btn-label"
                                                disabled={loading}
                                                onClick={() => onProceedWithoutPayment()}
                                            >
                                                <i style={{color:"green"}} className="bx bxs-badge-check label-icon align-bottom"></i> Proceed without Payment
                                            </button>
                                            }
                                            {fboState && fboState.canGenerateCertificate && 
                                            <button className="btn btn-dark btn-label"
                                                disabled={loading}
                                                onClick={() => onGenerateCertificate()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Generate Certificate
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Ticket #</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.isoRequest.requestNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Status</Label>
                                            <input className="form-control" readOnly={true}
                                                value={(fboState && fboState.isoRequest.status) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Request Type</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.isoRequest.requestType) || ""}
                                            />
                                        </Col>
                                        {fboState && fboState.showDocumentUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Document Controller</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditDocumentUser ? false : true}
                                                value={(fboState && fboState.isoRequest.documentUserId) || ""}
                                                onChange={e => onIsoRequestChange("documentUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.documentUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        {fboState && fboState.showAuditUser &&
                                        <Col xxl={4} md={6} className="d-none">
                                            <Label className="form-label">Auditor</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditAuditUser ? false : true}
                                                value={(fboState && fboState.isoRequest.auditUserId) || ""}
                                                onChange={e => onIsoRequestChange("auditUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.auditUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        {fboState && fboState.showTechnicalUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Technical Reviewer</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditTechnicalUser ? false : true}
                                                value={(fboState && fboState.isoRequest.technicalUserId) || ""}
                                                onChange={e => onIsoRequestChange("technicalUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.technicalUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        {fboState && fboState.showDecisionUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Decision Maker</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditDecisionUser ? false : true}
                                                value={(fboState && fboState.isoRequest.decisionUserId) || ""}
                                                onChange={e => onIsoRequestChange("decisionUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.decisionUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Reference #</Label>
                                            <input className="form-control" readOnly={true}
                                                value={(fboState && fboState.isoRequest.referenceNumber) || ""}
                                            />
                                        </Col>
                                        {fboState && fboState.showCompany &&
                                        <Fragment>
                                            <Col xxl={4} md={6}>
                                                <Label className="form-label">Company{fboState && fboState.companyRequired && <span className="required">*</span>}</Label>
                                                <select className="form-select" disabled={fboState && fboState.canEditCompany ? false : true}
                                                    value={(fboState && fboState.isoRequest.companyName) || ""}
                                                    onChange={e => onIsoRequestChange("companyName", e.target.value)}
                                                >
                                                    <option value={""}>...</option>
                                                    <option value={"Saudi Arabia"}>Saudi Arabia</option>
                                                    <option value={"Europe"}>Europe</option>
                                                </select>
                                            </Col>
                                            <Col xxl={4} md={6}>
                                                <Label className="form-label">Is History Data</Label>
                                                <select className="form-select" disabled={fboState && fboState.canEditCompany ? false : true}
                                                    value={(fboState && fboState.isoRequest.isHistoryData) || ""}
                                                    onChange={e => onIsoRequestChange("isHistoryData", e.target.value)}
                                                >
                                                    <option value={""}>...</option>
                                                    <option value={"Yes"}>Yes</option>
                                                    <option value={"No"}>No</option>
                                                </select>
                                            </Col>
                                        </Fragment>
                                        }
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Allow Access To <span className="text-primary" style={{fontSize:12}}><i>(Ticket will be shared with other user(s))</i></span></Label>
                                            <Typeahead
                                                id="fldReporterUserIds"
                                                multiple
                                                disabled={fboState && fboState.canEditReporter ? false : true}
                                                labelKey= {option => `${option.fullName}`}
                                                options={fboState && fboState.reporterUserList || []}
                                                placeholder="Select Reporter..."
                                                selected = {selectedReporter}
                                                onChange = {(item) => onReporterChange(item)}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Remarks</Label>
                                            <input className="form-control" maxLength={256} placeholder="optional..."
                                                value={(fboState && fboState.isoRequest.description) || ""}
                                                onChange={e => onIsoRequestChange("description", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {fboState && fboState.showCustomer &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Customer Information</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">CRM Name</Label>
                                            <select className="form-select" disabled={isCustomerReadonly()}
                                                value={(fboState && fboState.isoRequest.crmName) || ""}
                                                onChange={e => onIsoRequestChange("crmName", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                {fboState && fboState.crmList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.crmName}>{item.crmLabel}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Invoice Country</Label>
                                            <select className="form-select" disabled={isCustomerReadonly()}
                                                value={(fboState && fboState.isoRequest.crmCountry) || ""}
                                                onChange={e => onIsoRequestChange("crmCountry", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                {fboState && fboState.crmCountryList.filter(x => x.crmName == fboState.isoRequest.crmName).map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.countryName}>{item.countryLabel}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>&nbsp;</Col>
                                        {isCustomerReadonly() &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Customer Code</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].code + ' - ' + selectedCustomer[0].crmCountry) || ""}
                                            />
                                        </Col>
                                        }
                                        {!isCustomerReadonly() &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Customer Code</Label>
                                            <CustomerLookup
                                                id ="fldCustomerLookup2"
                                                crmName={(fboState && fboState.isoRequest.crmName) || ""}
                                                selected = {selectedCustomer}
                                                onChange = {(item) => onCustomerChange(item)}
                                            />
                                        </Col>
                                        }
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Organization Name</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].organizationName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Contact Name</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Address</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullAddress) || ""}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showInvoice &&
                            <Card className="mb-0 card-light">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setInvoiceInformationCollapsed(!invoiceInformationCollapsed)}>
                                        {invoiceInformationCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                                        {!invoiceInformationCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                                        Invoice Information {fboState && fboState.isoRequest.payment.invoiceStatus !== "Paid" && fboState.isoRequest.invoiceProceed === true ? "[Proceed without Payment] " : ""}({invoiceInformationCollapsed ? "Expand" : "Collapse"})
                                    </h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                                    <Collapse isOpen={!invoiceInformationCollapsed} className="collapse">
                                        <Row className="gy-2">
                                            <Col xxl={4} md={6}>
                                                <Label className="form-label">CRM Invoice #</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.crmInvoiceNumber) || ""}
                                                    onChange={e => onIsoRequestChange("crmInvoiceNumber", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={4} md={6}>
                                                <Label className="form-label">Tax Invoice #</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.invoiceStatus) || ""}
                                                    onChange={e => onIsoRequestChange("invoiceStatus", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={2} md={6}>
                                                <Label className="form-label">Status</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.total) || ""}
                                                    onChange={e => onIsoRequestChange("price", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={2} md={6}>
                                                <Label className="form-label">Iso Date</Label>
                                                <input className="form-control" readOnly={true}
                                                    defaultValue={(fboState && getFormattedDate(fboState.isoRequest.payment.isoDate)) || ""}
                                                />
                                            </Col>
                                            <Col xxl={4} md={6}>
                                                <Label className="form-label">Product Name</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.productName) || ""}
                                                    onChange={e => onIsoRequestChange("productName", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={2} md={6}>
                                                <Label className="form-label">Price</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.fee) || ""}
                                                    onChange={e => onIsoRequestChange("fee", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={2} md={6}>
                                                <Label className="form-label">VAT Amount</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.vat) || ""}
                                                    onChange={e => onIsoRequestChange("vat", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={2} md={6}>
                                                <Label className="form-label">Total Price</Label>
                                                <input type="text" className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.payment.total) || ""}
                                                    onChange={e => onIsoRequestChange("price", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={2} md={6}>
                                                <Label className="form-label">Currency</Label>
                                                <select className="form-select" disabled={true}
                                                    value={(fboState && fboState.isoRequest.payment.currency) || ""}
                                                    onChange={e => onIsoRequestChange("currency", e.target.value)}
                                                >
                                                    <option value={""}>...</option>
                                                    <option value="AED">UAE Dirham</option>
                                                    <option value="CNY">China Yuan</option>
                                                    <option value="EUR">Euro</option>
                                                    <option value="USD">USA Dollar</option>
                                                    <option value="LKR">Sri Lanka Rupee</option>
                                                    <option value="EGP">Egypt Pound</option>
                                                    <option value="SAR">Saudi Riyal</option>
                                                    <option value="INR">India Rupee</option>
                                                </select>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.isoRequest.id > 0 && (fboState.isoRequest.customerId || fboState.isoRequest.certificate.id) &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setRelatedIsoRequestCollapsed(!relatedIsoRequestCollapsed)}>
                                        {relatedIsoRequestCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                                        {!relatedIsoRequestCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                                        Linked / Customer Tickets ({relatedIsoRequestCollapsed ? "Expand" : "Collapse"})
                                    </h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                                    <Collapse isOpen={!relatedIsoRequestCollapsed} className="collapse">
                                        <RelatedIsoRequestList
                                            ticketId={fboState.isoRequest.id}
                                            customerId={fboState.isoRequest.customerId}
                                        />
                                    </Collapse>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showManDay &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Man Day Calculation</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={6} md={6}>
                                            <Label className="form-label">Man Day Standard{fboState && fboState.manDayRequired && <span className="required">*</span>}</Label>
                                            <select 
                                                className="form-select" disabled={fboState && fboState.canEditManDay ? false : true}
                                                value={(fboState && fboState.isoRequest.manDayStandardId) || ""}
                                                onChange={e => onManDayStandardChange("manDayStandardId", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {fboState && fboState.manDayStandardFBOList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.manDayStandard.id}>{item.manDayStandard.standardName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Number Of Personnel{fboState && fboState.manDayRequired && <span className="required">*</span>}</Label>
                                            <select 
                                                className="form-select" disabled={fboState && fboState.canEditManDay ? false : true}
                                                value={(fboState && fboState.isoRequest.manDayStandardConfigurationId) || ""}
                                                onChange={e => onManDayStandardNumberOfPersonnelChange("manDayStandardConfigurationId", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {selectedManDayStandardFBO && selectedManDayStandardFBO.manDayStandardConfigurationList 
                                                    && selectedManDayStandardFBO.manDayStandardConfigurationList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.minPersonnel} - {item.maxPersonnel}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Complexity{fboState && fboState.manDayRequired && <span className="required">*</span>}</Label>
                                            <select 
                                                className="form-select" disabled={fboState && fboState.canEditManDay ? false : true}
                                                value={(fboState && fboState.isoRequest.complexity) || ""}
                                                onChange={e => onManDayStandardComplexityChange("complexity", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                <option key="1" value="Low">Low</option>
                                                <option key="2" value="Medium">Medium</option>
                                                <option key="3" value="High">High</option>   
                                                
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Man Day(s)</Label>
                                                <input className="form-control" readOnly={fboState && fboState.canEditManDay ? false : true}
                                                    value={(fboState && fboState.isoRequest.complexityValue) || ""}
                                                    onChange={e => onIsoRequestChange("complexityValue", e.target.value)}
                                                />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showAuditor &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Auditor Information</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Auditor Name{fboState && fboState.auditorRequired && <span className="required">*</span>}</Label>
                                            {fboState && fboState.canEditAuditor &&
                                            <div className="input-group">
                                                <Lookup
                                                    clearButton 
                                                    allowNew ={false}
                                                    id ="fldAuditorLookup"
                                                    url = {`GetAuditorLookup?id=${fboState && fboState.isoRequest.manDayStandardId || ""}`}
                                                    defaultSearch={(fboState && fboState.isoRequest.auditUserId) || ""}
                                                    placeholder= "Select Auditor..."
                                                    labelKey= {option => `${option.fullName}`}
                                                    selected = {selectedAuditor}
                                                    onChange = {(item) => onAuditorChange(item)}
                                                    renderMenu = {AuditorRenderer}
                                                />
                                            </div>
                                            }
                                            {fboState && !fboState.canEditAuditor &&
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedAuditor.length > 0 && selectedAuditor[0].fullName) || ""}
                                            />
                                            }
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Email</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedAuditor.length > 0 && selectedAuditor[0].email) || ""}
                                            />
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Telephone Number</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedAuditor.length > 0 && selectedAuditor[0].telephoneNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedAuditor.length > 0 && selectedAuditor[0].mobileNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Address</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedAuditor.length > 0 && selectedAuditor[0].fullAddress) || ""}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showLocation &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Audit Locations{fboState && fboState.locationRequired && <span className="required">*</span>}</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            {fboState && fboState.canEditLocation &&
                                            <button className="btn btn-primary btn-label"
                                                onClick={() => onLocationAdd()}
                                            >
                                                <i className="ri-add-line label-icon align-bottom"></i> Add Location
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={3} md={12}>
                                            <Label className="form-label">Location Name</Label>
                                        </Col>
                                        <Col xxl={6} md={12}>
                                            <Label className="form-label">Address</Label>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <Label className="form-label">Audit Date / Time</Label>
                                        </Col>
                                        {/* <Col xxl={1} md={12}>
                                            <Label className="form-label">Approved</Label>
                                        </Col> */}
                                        {fboState && fboState.isoRequest.locations.length === 0 && <Col xxl={12} md={12}><Label className="form-label">No Locations added.</Label></Col>}
                                        {fboState && fboState.isoRequest.locations.map(function(item, index){
                                            return (
                                                <Fragment key={index}>
                                                    <Col xxl={3} md={12}>
                                                        <input className="form-control" placeholder="location name..." readOnly={fboState && fboState.canEditLocation ? false : true}
                                                            value={item.locationName || ""}
                                                            onChange={e => onLocationChange(index, "locationName", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={6} md={12}>
                                                        <input className="form-control" placeholder="location address..." readOnly={fboState && fboState.canEditLocation ? false : true}
                                                            value={item.locationAddress || ""}
                                                            onChange={e => onLocationChange(index, "locationAddress", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={2} md={12}>
                                                        {fboState && fboState.canEditLocation &&
                                                        <Flatpickr className="form-control"
                                                            value={getFormattedDateTime(item.auditDate) || ""}
                                                            onChange={([date]) => onLocationChange(index, "auditDate", date)}
                                                            options={{
                                                                enableTime: true,
                                                                dateFormat: "m/d/Y H:i"
                                                            }}
                                                        />
                                                        }
                                                        {fboState && !fboState.canEditLocation &&
                                                        <input className="form-control" readOnly={true}
                                                            defaultValue={(getFormattedDateTime(item.auditDate)) || ""}
                                                        />
                                                        }
                                                    </Col>
                                                    {/* <Col xxl={1} md={12}>
                                                        <div className="form-check pt-2">
                                                            <input type="checkbox" className="form-check-input"
                                                                checked={item.approved}
                                                                onChange={e => onLocationChange(index, "approved", e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col> */}
                                                </Fragment>
                                            );
                                        })}
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showCertificate &&
                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Certificate Information</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Certificate #{fboState && fboState.certificateRequired && <span className="required">*</span>}</Label>
                                            <input className="form-control" readOnly={!(fboState && fboState.canEditCertificateNumber)}
                                                value={(fboState && fboState.isoRequest.certificate.certificateNumber) || ""}
                                                onChange={e => onIsoCertificateChange("certificateNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Certificate Issue Date{fboState && fboState.certificateRequired && <span className="required">*</span>}</Label>
                                            {fboState && fboState.canEditCertificateDate &&
                                            <Flatpickr className="form-control"
                                                value={(fboState && getFormattedDate(fboState.isoRequest.certificate.certificateIssueDate)) || ""}
                                                onChange={([date]) => onIsoCertificateChange("certificateIssueDate", date)}
                                                options={{
                                                    dateFormat: "m/d/Y",
                                                    maxDate: "today"
                                                }}
                                            />
                                            }
                                            {fboState && !fboState.canEditCertificateDate &&
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(fboState && getFormattedDate(fboState.isoRequest.certificate.certificateIssueDate)) || ""}
                                            />
                                            }
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">1st Surveillance Date</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(fboState && getFormattedDate(fboState.isoRequest.certificate.surveillance1Date)) || ""}
                                            />
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">2nd Surveillance Date</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(fboState && getFormattedDate(fboState.isoRequest.certificate.surveillance2Date)) || ""}
                                            />
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Certificate Expiry Date</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(fboState && getFormattedDate(fboState.isoRequest.certificate.certificateExpiryDate)) || ""}
                                            />
                                        </Col>
                                        {fboState && fboState.showCertificateLabel &&
                                        <Col xxl={12} md={12}>
                                            <div className="form-label" dangerouslySetInnerHTML={{__html: fboState.isoRequest.expiryDateLabel}}></div>
                                        </Col>
                                        }
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Scope Of Work</Label>
                                            <textarea className="form-control" rows="5" maxLength={500}
                                                value={(fboState && fboState.isoRequest.certificate.scopeOfWork) || ""}
                                                onChange={e => onIsoCertificateChange("scopeOfWork", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12} className="m-0 align-right" style={{textAlign:"right"}}>
                                            <Label className="form-label">{(fboState && fboState.isoRequest.certificate.scopeOfWork && fboState.isoRequest.certificate.scopeOfWork.length) || "0"} of 500</Label>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.isoRequest.id > 0 &&
                                <IsoServiceDocumentGrid 
                                    serviceId={-1}
                                    refId={fboState.isoRequest.id}
                                    refName={"ISO_REQUEST"}
                                    parentId={fboState.isoRequest.id}
                                    parentName={"ISO_REQUEST"}
                                    isProduct={false}
                                    refreshAction={refreshAction}
                                    collapsed={"false"}
                                    canChange={fboState.canSave}
                                    onChange={onChangeDocument}
                                    showApplicationDocument={fboState.showApplicationDocument}
                                    canEditApplicationDocument={fboState.canEditApplicationDocument}
                                    showProposalDocument={fboState.showProposalDocument}
                                    canEditProposalDocument={fboState.canEditProposalDocument}
                                    showContractDocument={fboState.showContractDocument}
                                    canEditContractDocument={fboState.canEditContractDocument}
                                    showAuditPackageDocument={fboState.showAuditPackageDocument}
                                    canEditAuditPackageDocument={fboState.canEditAuditPackageDocument}
                                    showReleaseDocument={fboState.showReleaseDocument}
                                    canEditReleaseDocument={fboState.canEditReleaseDocument}
                                    showCertificateDocument={fboState.showCertificateDocument}
                                    canEditCertificateDocument={fboState.canEditCertificateDocument}
                                />
                            }

                            {fboState && fboState.isoRequest.id > 0 &&
                                <CommentGrid refId={fboState.isoRequest.id} refName="ISO_REQUEST" onCommentAdd={reloadAction} />
                            }
                        </Col>
                        
                        {fboState && fboState.isoRequest.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.isoRequest.id} name={"ISO_REQUEST"} refreshAction={refreshAction} />
                        </Col>
                        }

                        <ConfirmModal message={"Are you sure you want to Assign the ISO Ticket?"} show={showAssignMeModal} onCloseClick={onCloseModalClick} onYesClick={onAssignMeTicket} />
                        <ConfirmModal message={"Are you sure you want to \"Submit\" the ISO Ticket?"} show={showSubmitModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Sales Action\" the ISO Ticket?"} show={showSalesActionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Calculate Man Days\" the ISO Ticket?"} show={showCalculateManDaysModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Sales Support\" the ISO Ticket?"} show={showSalesSupportModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Customer Approved\" the ISO Ticket?"} show={showCustomerApprovedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Audit In Progress\" the ISO Ticket?"} show={showAuditInProgressModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Technical Review\" the ISO Ticket?"} show={showTechnicalReviewModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Final Decision\" the ISO Ticket?"} show={showFinalDecisionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Completed\" the ISO Ticket?"} show={showCompletedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Closed\" the ISO Ticket?"} show={showClosedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Aborted\" the ISO Ticket?"} show={showAbortedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Reset To Draft\" the ISO Ticket?"} show={showResetToDraftModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />

                        <ConfirmModalAdvanced show={showProceedWithoutPaymentModal} onCloseClick={onCloseModalClick} onYesClick={onSaveProceedWithoutPayment}>
                            <div className="pb-2">Are you sure you want to Mark the ticket to "Proceed without Payment"?.</div>
                            <Col xxl={12} md={12}>
                                <Label className="form-label">Enter Any Comments</Label>
                                <textarea className="form-control" rows="5"
                                    value={currentMessage || ""}
                                    onChange={e => setCurrentMessage(e.target.value)}
                                />
                            </Col>
                        </ConfirmModalAdvanced>

                        <ConfirmModal message={"Are you sure you want to Generate the \"Certificate\"?."} show={showGenerateCertificateModal} onCloseClick={onCloseModalClick} onYesClick={onSaveGenerateCertificate} />
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default IsoRequestEditScreen;