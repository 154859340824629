import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import ServiceDocumentGrid from "../components/ServiceDocumentGrid";

import { useDispatch, useSelector } from "react-redux";
import * as auditorActions from "../actions/AuditorActions";

const AuditorEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.auditor.loadingFBO);
    const fbo = useSelector((state) => state.auditor.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedStandard, setSelectedStandard] = React.useState([]);

    React.useEffect(() => {
	    dispatch(auditorActions.loadAuditor(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedStandard([]);
        
        if(fbo) {
            reset(fbo.auditor);

            if(fbo.auditorStandardList != null) {
                setSelectedStandard(fbo.auditorStandardList);
            }
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onChangeDocument = (isProduct) => {
        setRefreshAction(!refreshAction);
    }

    const onAuditorChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.auditor[name] = value;
        }
        else if(value === false) {
            copy_fboState.auditor[name] = false;
        }
        else {
            copy_fboState.auditor[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onStandardChange = (items) => {
        setSelectedStandard(items);
        
        if(items.length > 0) {
            var ids = [];
            
            items.forEach(element => {
                ids.push(element.id);
            });

            onAuditorChange("standardId", ids.toString());
        }
        else {
            onAuditorChange("standardId", null);
        }
    }

    const onSaveAuditor = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(auditorActions.saveAuditor(fboState, navigate));
        }
    }

    const onCancelAuditor = () => {
        setFBOState(fbo);

        if(fbo.auditorStandardList != null) {
            setSelectedStandard(fbo.auditorStandardList);
        }
        else {
            setSelectedStandard([]);
        }

        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Edit Auditor"} />
                    <Row>
                        <Col lg={fboState && fboState.auditor.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Auditor</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveAuditor}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Auditor
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelAuditor}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">First Name</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.firstName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Last Name</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.lastName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.email) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.telephoneNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.mobileNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Man Day Standard <span className="text-primary" style={{fontSize:12}}><i>(Auditors would be allowed to assign tickets based on selected standards)</i></span></Label>
                                            <Typeahead
                                                id="fldStandardIds"
                                                multiple
                                                disabled={false}
                                                labelKey= {option => `${option.standardShortName}`}
                                                options={fboState && fboState.standardList || []}
                                                placeholder="Select Standard..."
                                                selected = {selectedStandard}
                                                onChange = {(item) => onStandardChange(item)}
                                            />
                                        </Col>
                                        <Col xxl={8} md={8}>
                                            <Label className="form-label">Address</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.auditor.address) || ""}
                                                onChange={e => onAuditorChange("address", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">City</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.auditor.city) || ""}
                                                onChange={e => onAuditorChange("city", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">State</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.auditor.state) || ""}
                                                onChange={e => onAuditorChange("state", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.auditor.country) || ""}
                                                onChange={e => onAuditorChange("country", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Zip Code</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.auditor.zipCode) || ""}
                                                onChange={e => onAuditorChange("zipCode", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {fboState && fboState.auditor.id > 0 &&
                                <ServiceDocumentGrid
                                    serviceId={-2}
                                    refId={fboState.auditor.id}
                                    refName={"AUDITOR"}
                                    parentId={fboState.auditor.id}
                                    parentName={"AUDITOR"}
                                    isProduct={false}
                                    collapsed={"false"}
                                    canAddDocument={true}
                                    canDeleteDocument={true}
                                    canAddCertificate={false}
                                    canDeleteCertificate={false}
                                    onChange={onChangeDocument}
                                />
                            }
                        </Col>
                        
                        {fboState && fboState.auditor.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.auditor.id} name={"AUDITOR"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default AuditorEditScreen;