import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import { api } from "../utils/index";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

const FormReviewSASO = (props) => {
    const [reviewFormCollapsed, setReviewFormCollapsed] = React.useState(true);
    
    return(
        <Card className="card-secondary mb-3">
            <CardHeader className="border-0 d-flex align-items-center">
                <CardLoader loading={props.loading} />
                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setReviewFormCollapsed(!reviewFormCollapsed)}>
                    {reviewFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                    {!reviewFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                    Application Review Form ({reviewFormCollapsed ? "Expand" : "Collapse"})
                </h5>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <Collapse isOpen={!reviewFormCollapsed} className="collapse">
                    <Row className="gy-2" style={{backgroundColor:"white"}}>
                        <Col xxl={12} md={12}>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm1 ? "" : "table-form-readonly"}`}>
                                <tbody>
                                    <tr>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"65%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr className="header">
                                        <td className="index-col">#</td>
                                        <td>Points to be checked<sup>8</sup></td>
                                        <td colSpan={3}>Sales Team</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.1</b></td>
                                        <td>Is customer and product information sufficient to perform evaluation?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "InformationSufficient") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "InformationSufficient", "Yes")} onChange={e => props.setFieldValue("reviewForm", "InformationSufficient", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "InformationSufficient") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "InformationSufficient", "No")} onChange={e => props.setFieldValue("reviewForm", "InformationSufficient", "No", e.target.checked)} /> No
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.2</b></td>
                                        <td>Is any known difference in understanding between the TÜV AUSTRIA and the client resolved? (Such as contract or certification agreement)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "KnownDifference") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "KnownDifference", "Yes")} onChange={e => props.setFieldValue("reviewForm", "KnownDifference", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "KnownDifference") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "KnownDifference", "No")} onChange={e => props.setFieldValue("reviewForm", "KnownDifference", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "KnownDifference") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "KnownDifference", "N/A")} onChange={e => props.setFieldValue("reviewForm", "KnownDifference", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.3</b></td>
                                        <td>Is the scope of certification sought defined?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeSought") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeSought", "Yes")} onChange={e => props.setFieldValue("reviewForm", "ScopeSought", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "ScopeSought") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "ScopeSought", "No")} onChange={e => props.setFieldValue("reviewForm", "ScopeSought", "No", e.target.checked)} /> No
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.4</b></td>
                                        <td>Does the TASHA certification body have the competence and capability to perform the certification activity?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CertificationActivity") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CertificationActivity", "Yes")} onChange={e => props.setFieldValue("reviewForm", "CertificationActivity", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CertificationActivity") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CertificationActivity", "No")} onChange={e => props.setFieldValue("reviewForm", "CertificationActivity", "No", e.target.checked)} /> No
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col"><b>2.5</b></td>
                                        <td>Are the means available to perform all evaluation activities? Please check Records.</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "EvaluationActivities") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "EvaluationActivities", "Yes")} onChange={e => props.setFieldValue("reviewForm", "EvaluationActivities", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "EvaluationActivities") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "EvaluationActivities", "No")} onChange={e => props.setFieldValue("reviewForm", "EvaluationActivities", "No", e.target.checked)} /> No
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm1 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={5}>Result of Application Review</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"50%"}}>The Certification Application is acceptable?</td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CertificationAcceptable", "Yes")} onChange={e => props.setFieldValue("reviewForm", "CertificationAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("reviewForm", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("reviewForm", "CertificationAcceptable", "No")} onChange={e => props.setFieldValue("reviewForm", "CertificationAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Signature</td>
                                        <td>Date</td>
                                    </tr>
                                    <tr>
                                        <td>{(props.fboState && props.fboState.reviewForm && props.fboState.reviewForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td>
                                            {props.fboState && props.fboState.reviewForm && props.fboState.reviewForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.reviewForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td>
                                            <MyDateRenderer value={(props.fboState && props.fboState.reviewForm && props.fboState.reviewForm.ticketForm && props.fboState.reviewForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%", paddingTop:10}}>------------------------------------------</td>
                                        <td style={{width:"30%"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>1</sup> If it is Type5, please arrange for Saudi Q-Mark.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>2</sup> Test report and factory audit report should be still valid to proceed Technical Review.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>3</sup> Application review may be carried out by different appointed persons or simultaneously by the same person.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>4</sup> If there is any missing file in the <b>“Records Check”</b> section, please connect with the client. This section will specifically look for the existence of a document. Detailed examination will be done in the evaluation section. Please forward the file with the relevant attachments as per each Technical Regulation to the operations department for evaluation.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>5</sup> Quality Documents can be one of them as per CAI; EN ISO/IEC 17025 Test Report, PID (Product Identical Declaration), Type Certificates (SASO, IEC, GSO etc.) Other Certificates (CE, Bluesign, Oeko-Tex), In-house Test can be found in above link.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>6</sup> Declaration of Conformity can be found in annexes of related Technical Regulation. It must be published by the client within their letterhead.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>7</sup> Risk Assessment Form must be published by the client within their letterhead.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>8</sup> If there is any missing information in <b>“Points to be Checked”</b> section, please connect with the client.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td colSpan={3}><hr/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"25%", textAlign:"left"}}>Status: Approved</td>
                                        <td style={{width:"50%", textAlign:"center"}}>FM-GMAP-PC-SS-001b Rev.: 00, 25.07.2023</td>
                                        <td style={{width:"25%", textAlign:"center"}}>Classification: Public</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default FormReviewSASO;