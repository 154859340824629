import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner, Form, Nav, NavItem, NavLink, TabContent, TabPane, Progress } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";

import { useDispatch, useSelector } from "react-redux";
import * as isoRequestActions from "../actions/IsoRequestActions";
import * as commonActions from "../actions/CommonActions";

const IsoRequestAddScreen  = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.iso.loadingFBO);
    const fbo = useSelector((state) => state.iso.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
        
    const [progressbarvalue, setprogressbarvalue] = React.useState(0);
    const [activeTab, setactiveTab] = React.useState(1);
    const [passedSteps, setPassedSteps] = React.useState([1]);
    const [loadingLocal, setLoadingLocal] = React.useState(false);
    const [certificateValid, setCertificateValid] = React.useState(true);

    React.useEffect(() => {
	    dispatch(isoRequestActions.loadIsoRequest(null, navigate));
    }, []);

    React.useEffect(() => {
		setFBOState(fbo);

        if(fbo != null) {
            reset(fbo.isoRequest);
        }
        clearErrors();
    }, [fbo]);

    const isInitialAudit = () => {
        return fboState && fboState.isoRequest.requestType && fboState.isoRequest.requestType === "Initial Audit";
    }

    async function toggleTab(tab, value) {
        if(value === 100) {
            var valid = await trigger(['requestType']);
            var validateCertificateNumber = false;
            var isCertificateValid = true;
            
            if(isInitialAudit()) {
                validateCertificateNumber = false;
            }
            else {
                validateCertificateNumber = true;
            }

            if(valid) {
                if(validateCertificateNumber) {
                    if(fboState.isoRequest.certificate.certificateNumber) {
                        isCertificateValid = true;
                    }
                    else {
                        isCertificateValid = false;
                        valid = false;
                    }
                }
            }

            setCertificateValid(isCertificateValid);

            if(valid && isCertificateValid) {
                if(validateCertificateNumber) {
                    setLoadingLocal(true);
                    var data = await isoRequestActions.getIsoCertificate(fboState.isoRequest.certificate.certificateNumber);
                    setLoadingLocal(false);

                    if(data.success) {
                        if (activeTab !== tab) {
                            var modifiedSteps = [...passedSteps, tab];
                
                            if (tab >= 1 && tab <= 4) {
                                setactiveTab(tab);
                                setPassedSteps(modifiedSteps);
                            }
                        }
                
                        setprogressbarvalue(value);
                    }
                    else{
                        dispatch(commonActions.setErrorMessage(data.errorMessage));
                    }
                }
                else {
                    if (activeTab !== tab) {
                        var modifiedSteps = [...passedSteps, tab];
            
                        if (tab >= 1 && tab <= 4) {
                            setactiveTab(tab);
                            setPassedSteps(modifiedSteps);
                        }
                    }
            
                    setprogressbarvalue(value);
                }
            }
        }
        else {
            if (activeTab !== tab) {
                var modifiedSteps = [...passedSteps, tab];
    
                if (tab >= 1 && tab <= 4) {
                    setactiveTab(tab);
                    setPassedSteps(modifiedSteps);
                }
            }
    
            setprogressbarvalue(value);
        }
    }

    const onIsoRequestChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.isoRequest[name] = value;
        }
        else if(value === false) {
            copy_fboState.isoRequest[name] = false;
        }
        else {
            copy_fboState.isoRequest[name] = null;
        }

        if(name === "requestType") {
            copy_fboState.isoRequest.certificate["certificateNumber"] = null;
        }
        
        setFBOState(copy_fboState);
    }

    const onIsoCertificateChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.isoRequest.certificate[name] = value;
        }
        else if(value === false) {
            copy_fboState.isoRequest.certificate[name] = false;
        }
        else {
            copy_fboState.isoRequest.certificate[name] = null;
        }
        
        setFBOState(copy_fboState);
    }

    const onSaveIsoRequest = async() => {
        const valid = await trigger();
        
        if(valid) {
            dispatch(isoRequestActions.saveIsoRequest(fboState, "", "", false, navigate));
        }
    }

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Add ISO Ticket"} />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardLoader loading={loading || loadingLocal} />
                                <CardBody>
                                    <div className="text-center pt-3 pb-4 mb-1">
                                        <h5>Create New ISO Ticket</h5>
                                    </div>
                                    
                                    <div className="progress-nav mb-4">
                                        <Progress
                                            value={progressbarvalue}
                                            style={{ height: "1px" }}
                                        />

                                        <Nav className="nav-pills progress-bar-tab custom-nav" role="tablist">
                                            <li className="nav-item">
                                                <button type="button" 
                                                    className={classnames(
                                                    {
                                                        active: activeTab === 1,
                                                        done: activeTab <= 2 && activeTab >= 0,
                                                    },
                                                    "rounded-pill nav-link"
                                                    )}
                                                >1</button>
                                            </li>
                                            <li className="nav-item">
                                                <button type="button" 
                                                    className={classnames(
                                                    {
                                                        active: activeTab === 2,
                                                        done: activeTab <= 2 && activeTab > 1,
                                                    },
                                                    "rounded-pill nav-link"
                                                    )}
                                                >2</button>
                                            </li>
                                        </Nav>
                                    </div>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1}>
                                            <div>
                                                <div className="mb-4">
                                                    <div>
                                                        <h5 className="mb-1">Enter Any Comments</h5>
                                                    </div>
                                                </div>
                                                <Row className="gy-2">
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Ticket #</Label>
                                                        <input type="text" className="form-control" readOnly={true}
                                                            value={(fboState && fboState.isoRequest.requestNumber) || ""}
                                                        />
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Status</Label>
                                                        <input type="text" className="form-control" readOnly={true}
                                                            value={(fboState && fboState.isoRequest.status) || ""}
                                                        />
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Request Type<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.requestType ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.isoRequest.requestType) || ""}
                                                            {...register("requestType", { required: true })}
                                                            onChange={e => onIsoRequestChange("requestType", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && fboState.requestTypeList && fboState.requestTypeList.map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.code}>{item.description}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    {fboState && fboState.isoRequest.requestType && fboState.isoRequest.requestType !== "Initial Audit" &&
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Certificate #<span className="required">*</span></Label>
                                                        <input type="text" className={`form-control ${certificateValid ? "" : "is-invalid"}`}
                                                            value={(fboState && fboState.isoRequest.certificate.certificateNumber) || ""}
                                                            onChange={e => onIsoCertificateChange("certificateNumber", e.target.value)}
                                                        />
                                                    </Col>
                                                    }
                                                    <Col xxl={12} md={12}>
                                                        <Label className="form-label">Remarks</Label>
                                                        <input type="text" className="form-control"
                                                            value={(fboState && fboState.isoRequest.description) || ""}
                                                            onChange={e => onIsoRequestChange("description", e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button
                                                    disabled={loading || loadingLocal}
                                                    type="button"
                                                    className="btn btn-primary btn-label right ms-auto nexttab nexttab"
                                                    onClick={() => {
                                                    toggleTab(activeTab + 1, 100);
                                                    }}
                                                >
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                    Next
                                                </button>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId={2}>
                                            <div>
                                                <div className="mb-4">
                                                    <div>
                                                        <h5 className="mb-1">Fill ISO Ticket Information</h5>
                                                    </div>
                                                </div>
                                                <Row className="gy-2">
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">CRM Name<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.crmName ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.isoRequest.crmName) || ""}
                                                            {...register("crmName", { required: true })}
                                                            onChange={e => onIsoRequestChange("crmName", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && fboState.crmList.map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.crmName}>{item.crmLabel}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Invoice Country<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.crmCountry ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.isoRequest.crmCountry) || ""}
                                                            {...register("crmCountry", { required: true })}
                                                            onChange={e => onIsoRequestChange("crmCountry", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && fboState.crmCountryList.filter(x => x.crmName == fboState.isoRequest.crmName).map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.countryName}>{item.countryLabel}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>                                                  
                                                </Row>
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button
                                                    disabled={loading || loadingLocal}
                                                    type="button"
                                                    className="btn btn-link text-decoration-none btn-label previestab"
                                                    onClick={() => {
                                                        toggleTab(activeTab - 1, 0);
                                                    }}
                                                >
                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                    Back
                                                </button>
                                                <button
                                                    disabled={loading || loadingLocal}
                                                    type="button"
                                                    className="btn btn-primary btn-label right ms-auto nexttab nexttab"
                                                    onClick={() => onSaveIsoRequest()}
                                                >
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                    Submit
                                                </button>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default IsoRequestAddScreen;