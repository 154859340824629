import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import ServiceDocumentGrid from "../components/ServiceDocumentGrid";

import { useDispatch, useSelector } from "react-redux";
import * as inspectorActions from "../actions/InspectorActions";

const InspectorEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.inspector.loadingFBO);
    const fbo = useSelector((state) => state.inspector.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    
    React.useEffect(() => {
	    dispatch(inspectorActions.loadInspector(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
                
        if(fbo) {
            reset(fbo.inspector);
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onChangeDocument = (isProduct) => {
        setRefreshAction(!refreshAction);
    }

    const onInspectorChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.inspector[name] = value;
        }
        else if(value === false) {
            copy_fboState.inspector[name] = false;
        }
        else {
            copy_fboState.inspector[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveInspector = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(inspectorActions.saveInspector(fboState, navigate));
        }
    }

    const onCancelInspector = () => {
        setFBOState(fbo);

        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Edit Inspector"} />
                    <Row>
                        <Col lg={fboState && fboState.inspector.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Inspector</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveInspector}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Inspector
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelInspector}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">First Name</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.firstName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Last Name</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.lastName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.email) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.telephoneNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.userProfile.mobileNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={8} md={8}>
                                            <Label className="form-label">Address</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.inspector.address) || ""}
                                                onChange={e => onInspectorChange("address", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">City</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.inspector.city) || ""}
                                                onChange={e => onInspectorChange("city", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">State</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.inspector.state) || ""}
                                                onChange={e => onInspectorChange("state", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.inspector.country) || ""}
                                                onChange={e => onInspectorChange("country", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Zip Code</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.inspector.zipCode) || ""}
                                                onChange={e => onInspectorChange("zipCode", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {fboState && fboState.inspector.id > 0 &&
                                <ServiceDocumentGrid
                                    serviceId={-3}
                                    refId={fboState.inspector.id}
                                    refName={"INSPECTOR"}
                                    parentId={fboState.inspector.id}
                                    parentName={"INSPECTOR"}
                                    isProduct={false}
                                    collapsed={"false"}
                                    canAddDocument={true}
                                    canDeleteDocument={true}
                                    canAddCertificate={false}
                                    canDeleteCertificate={false}
                                    onChange={onChangeDocument}
                                />
                            }
                        </Col>
                        
                        {fboState && fboState.inspector.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.inspector.id} name={"INSPECTOR"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default InspectorEditScreen;