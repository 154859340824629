import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { ListGroup, ListGroupItem } from 'reactstrap';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import logoApproved from "../assets/images/approved.jpeg";
import logoRejected from "../assets/images/rejected.jpeg";

import MyDateRenderer from "./MyDateRenderer";
import ConfirmModal from "./ConfirmModal";
import ComplianceLabel from "./ComplianceLabel";
import CustomerCategoryLabel from "./CustomerCategoryLabel";

import { useDispatch, useSelector } from "react-redux";
import * as kanbanActions from "../actions/KanbanActions";

const KanbanList = (props) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.login.user);

    const [selectedId, setSelectedId] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedUserId, setSelectedUserId] = React.useState(-1);
    const [showAssignMeModal, setShowAssignMeModal] = React.useState(false);
    const [showAssignModal, setShowAssignModal] = React.useState(false);

    const getInvoiceNumberDisplay = (item) => {
        var value = "";

        if(item.regionShortCode && item.regionShortCode.length > 0) {
            value += item.regionShortCode;
        }

        if(item.crmInvoiceNumber && item.crmInvoiceNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.crmInvoiceNumber;
        }

        if(item.taxInvoiceNumber && item.taxInvoiceNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.taxInvoiceNumber;
        }

        if(item.sasoRequestNumber && item.sasoRequestNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.sasoRequestNumber;
        }

        if(item.fasehRequestNumber && item.fasehRequestNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.fasehRequestNumber;
        }

        return value;
    }

    const onCloseModalClick = () => {
        setSelectedId(0);
        setSelectedIndex(-1);
        setSelectedUserId(-1);
        setShowAssignMeModal(false);
        setShowAssignModal(false);
    }

    const assignMeTicket = (id, index) => {
        setSelectedId(id);
        setSelectedIndex(index);
        setSelectedUserId(user.id);
        setShowAssignMeModal(true);
    }

    const onAssignMeTicket = async () => {
        if(selectedId > 0 && selectedIndex >= 0 && selectedUserId > 0) {
            dispatch(kanbanActions.assignTicket(selectedId, selectedUserId, true, props.list, selectedIndex));
        }

        onCloseModalClick();
    }

    const assignTicket = (id, index, userId) => {
        setSelectedId(id);
        setSelectedIndex(index);
        setSelectedUserId(userId);
        setShowAssignModal(true);
    }

    const onAssignTicket = async () => {
        if(selectedId > 0 && selectedIndex >= 0 && selectedUserId > 0) {
            dispatch(kanbanActions.assignTicket(selectedId, selectedUserId, false, props.list, selectedIndex));
        }

        onCloseModalClick();
    }

    return (
        <div className="tasks-list">
            <div className="d-flex mb-2">
                <div className="flex-grow-1">
                    <h6 className="fs-14 text-uppercase fw-semibold mb-0">{props.title} <small className={`badge ${props.titleClass} align-bottom ms-1 totaltask-badge`}>{props.list ? props.list.length : 0}</small></h6>
                </div>
            </div>
            <SimpleBar className="tasks-wrapper px-3 mx-n3" forceVisible={true} autoHide={false}>
                <div className="tasks">
                    {props.list && props.list.map(function(item, index){
                        return(
                            <div className={`card tasks-box ${item.ticketType === "CONTAINER" ? ["card-light", "border", "border-1", props.borderClass].join(" ") : ""}`} key={index}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
                                            <NavLink to={`/request-edit/${item.id}`} className="text-body d-block">{item.requestNumber} {item.groupCode ? "[" + item.groupCode + "]" : ""} 
                                                {item.pdApproved === true && <span><img src={logoApproved} alt="" height="30" title="PD Approved" /></span>}
                                                {item.pdApproved === false && <span><img src={logoRejected} alt="" height="30" title="PD Rejected" /></span>}
                                                <ComplianceLabel value={item.complianceState || ""} />
                                            </NavLink>
                                        </h6>
                                        {/* <div className="dropdown">
                                            <a href="#" className="text-muted" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false"><i className="ri-more-fill"></i></a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                                <li><a className="dropdown-item" href="apps-tasks-details.html"><i className="ri-eye-fill align-bottom me-2 text-muted"></i> View</a></li>
                                                <li><a className="dropdown-item" href="#"><i className="ri-edit-2-line align-bottom me-2 text-muted"></i> Edit</a></li>
                                                <li><a className="dropdown-item" data-bs-toggle="modal" href="#deleteRecordModal"><i className="ri-delete-bin-5-line align-bottom me-2 text-muted"></i> Delete</a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    {item.customerName &&
                                    <p className="mb-0 fs-11 flex-grow-1 text-truncate text-dark" style={{marginBottom:0}}>
                                        {item.customerName}
                                        <CustomerCategoryLabel value={item.customerCategory || ""} />
                                    </p>
                                    }
                                    <p className="fs-11 flex-grow-1 text-truncate text-muted">
                                        {getInvoiceNumberDisplay(item)}
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <span className="badge" style={{backgroundColor: item.serviceColorCode}}>{item.serviceName}</span>
                                            {item.certificateCategory && <Fragment><br /><span className="badge bg-danger">{item.certificateCategory}</span></Fragment>}
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div>
                                                {props.title === "New" && user && user.canAssignMe && !user.canAssignAny && !item.groupUserName && item.serviceId !== 4 &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "Phase 1 Evaluation" && user && user.canAssignMe && !user.canAssignAny && !item.groupUserName && item.serviceId !== 4 &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "New" && user && user.canAssignMePd && !user.canAssignAny && !item.pdGroupUserName && item.serviceId === 4 &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "PD Review" && user && user.canAssignMePd && !item.pdGroupUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "PD Support" && user && user.canAssignMePd && !item.pdGroupUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "Sampling" && user && user.canAssignMePd && !item.pdGroupUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "Final Decision" && user && user.canAssignMeDecisionMaker && !item.decisionUserName && item.serviceId !== 4 &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "Final Decision" && user && user.canAssignMePd && !item.decisionUserName && item.serviceId === 4 &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "New" && user && user.canAssignAny && !item.groupUserName && item.serviceId !== 4 &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.groupUserList.filter(x => x.groupId == item.groupId && x.isTechnicalUser === true).map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.userId}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                                {props.title === "Phase 1 Evaluation" && user && user.canAssignAny && !item.groupUserName && item.serviceId !== 4 &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.groupUserList.filter(x => x.groupId == item.groupId && x.isTechnicalUser === true).map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.userId}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                                {props.title === "New" && user && user.canAssignAny && !item.pdGroupUserName && item.serviceId === 4 &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.pdGroupUserList.filter(x => x.groupId == item.pdGroupId).map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.userId}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.groupUserName}</span>
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.decisionUserName}</span>
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.pdGroupUserName}</span>
                                            </div>
                                            {/* <div className="avatar-group">
                                                <a href="#" className="avatar-group-item shadow" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Alexis">
                                                    <img src={require("../assets/images/users/avatar-6.jpg")} alt="" className="rounded-circle avatar-xs" />
                                                </a>
                                                <a href="#" className="avatar-group-item shadow" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Nancy">
                                                    <img src={require("../assets/images/users/avatar-5.jpg")} alt="" className="rounded-circle avatar-xs" />
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                    {item.ticketType === "CONTAINER" && 
                                    <div className="mt-3">
                                        <ListGroup>
                                            {item.draftCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Draft <span className="badge bg-success">{item.draftCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.newCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                New <span className="badge bg-success">{item.newCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.inProgressCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                In Progress <span className="badge bg-success">{item.inProgressCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.salesActionCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Sales Action <span className="badge bg-success">{item.salesActionCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.pdReviewCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                PD Review <span className="badge bg-success">{item.pdReviewCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.pdSupportCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                PD Support <span className="badge bg-success">{item.pdSupportCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.samplingCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Sampling <span className="badge bg-success">{item.samplingCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.scheduledCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Scheduled <span className="badge bg-success">{item.scheduledCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.waitingCorrectiveActionCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Waiting Corrective Action <span className="badge bg-success">{item.waitingCorrectiveActionCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.phase1EvaluationCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Phase 1 Evaluation <span className="badge bg-success">{item.phase1EvaluationCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.inspectionCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Inspection <span className="badge bg-success">{item.inspectionCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.phase2EvaluationCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Phase 2 Evaluation <span className="badge bg-success">{item.phase2EvaluationCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.finalDecisionCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Final Decision <span className="badge bg-success">{item.finalDecisionCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.completedCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Completed <span className="badge bg-success">{item.completedCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.closedCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Closed <span className="badge bg-success">{item.closedCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.stoppedCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Stopped <span className="badge bg-success">{item.stoppedCount}</span>
                                            </ListGroupItem>
                                            }
                                            {item.abortedCount > 0 &&
                                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                                Aborted <span className="badge bg-success">{item.abortedCount}</span>
                                            </ListGroupItem>
                                            }
                                        </ListGroup>
                                    </div>
                                    }
                                </div>
                                <div className="card-footer border-top-dashed" style={{paddingTop:5, paddingBottom:5}}>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <ul className="link-inline mb-0" style={{paddingLeft:0}}>
                                                <li className="list-inline-item">
                                                    <i className="ri-user-add-line align-bottom"></i> {item.addedBy} | <MyDateRenderer value={item.dateAdded} format="mm/dd/yy" />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <ul className="link-inline mb-0">
                                                {/* <li className="list-inline-item">
                                                    <i className="ri-eye-line align-bottom"></i> 04
                                                </li> */}
                                                <li className="list-inline-item">
                                                    <i className="ri-question-answer-line align-bottom"></i> {item.commentCount}
                                                </li>
                                                <li className="list-inline-item">
                                                    <i className="ri-attachment-2 align-bottom"></i> {item.documentCount}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress progress-sm bg-soft-success">
                                    <div className={`progress-bar ${props.titleClass}`} style={{width: "100%"}} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        );
                    })}

                    {props.list && props.list.length === 0 &&
                    <div className="">
                        <div className="">
                            <div className="d-flex mb-2">
                                <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title"><div className="text-body d-block">No {props.title} Tickets</div></h6>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </SimpleBar>

            <ConfirmModal message={"Are you sure you want to Assign the Ticket?"} show={showAssignMeModal} onCloseClick={onCloseModalClick} onYesClick={onAssignMeTicket} />
            <ConfirmModal message={"Are you sure you want to Assign the Ticket?"} show={showAssignModal} onCloseClick={onCloseModalClick} onYesClick={onAssignTicket} />
        </div>
    );
};

export default KanbanList;