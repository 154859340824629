import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner, Table } from 'reactstrap';

import { api } from "../utils/index";

import CardLoader from "../components/CardLoader";
import MyDateRenderer from "../components/MyDateRenderer";

import { useDispatch } from "react-redux";
import * as commonActions from "../actions/CommonActions";
import * as isoRequestActions from "../actions/IsoRequestActions";

const IsoRequestCertificateView  = (props) => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const [loadingLocal, setLoadingLocal] = React.useState(true);
    const [list, setList] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            setList([]);

            setLoadingLocal(true);
            var data = await isoRequestActions.getCertificate(id);
            setLoadingLocal(false);

            if(data.success) {
                setList(data.list);
            }
            else {
                setList([]);
                dispatch(commonActions.setErrorMessage(data.errorMessage));
            }
        }

        fetchData();
    }, [id]);

    const onDocumentLoaded = () => {
        setLoadingLocal(false);
    }
    
    return(
        <Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <Col lg={12}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">Certificate Information</h5>
                                                </div>
                                                <div className="mt-4">
                                                    <CardLoader loading={loadingLocal} />
                                                    <Row className="gy-2">
                                                        <Col xxl={12} md={12}>
                                                            <Table className="table-hover table-bordered align-middle mb-0">
                                                                <thead className="table-dark">
                                                                    <tr>
                                                                        <th>Organization</th>
                                                                        <th>Standard</th>
                                                                        <th>Initial Date</th>
                                                                        <th>Certificate #</th>
                                                                        <th>Issue Date</th>
                                                                        <th>Valid Date</th>
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {list.length == 0 && <tr><td colSpan={7}>No Certificate found.</td></tr>}
                                                                    {list.map(function(item, index){
                                                                        return(
                                                                            <tr key={index}>
                                                                                <td>{item.customerName}</td>
                                                                                <td>{item.standardShortName}</td>
                                                                                <td><MyDateRenderer value={item.certificateInitialDate} format={"mm/dd/yyyy"} /></td>
                                                                                <td>{item.certificateNumber}</td>
                                                                                <td><MyDateRenderer value={item.certificateIssueDate}  format={"mm/dd/yyyy"} /></td>
                                                                                <td><MyDateRenderer value={item.certificateExpiryDate}  format={"mm/dd/yyyy"} /></td>
                                                                                <td>{item.certificateStatus}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} Ticketing System. Crafted with <i className="mdi mdi-heart text-danger"></i> by TUV</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </Fragment>
    );
}

export default IsoRequestCertificateView;